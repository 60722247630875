@mixin website-register-theme($theme) {
  app-register {
    .website-register {
      background-image: url("/assets/images/bglogin.jpg");
      background-position: center top;
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
      position: relative;

      &__wrapper {
        margin: 0 auto;
        background-color: rgba(48, 183, 215, 0.41);
        padding: 2.5em 2em;
      }

      &__container {
        padding: 0;

        background-color: #fff;
        opacity: 0.8;

        img {
          width: 60%;
          height: 60%;
        }
      }
    }
  }
}
