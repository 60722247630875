@mixin website-login-theme($theme) {
  app-login {
    .website-login {
      background-image: url("../../../../assets/images/bglogin.jpg");
      background-position: center top;
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
      position: relative;
      &__wrapper {
        margin: 0 auto;
        background-color: rgba(48, 183, 215, 0.41);
        padding: 7em 0 7em;
        @media screen and (max-width: 599px) {
          padding: 3em 0 3em;
        }
      }

      &__container {
        padding: 0 2em;
        border-radius: 5px;
        background-color: #fff;
        opacity: 0.8;

        img {
          height: 100px;
          width: 100px;
        }

        a {
          color: lighten($light-blue-text, 20%);
          font-size: 1.3em;
          text-decoration: none;

          &:hover {
            color: $dark-blue-text;
          }
        }

        mat-icon {
          color: lighten($light-blue-text, 20%);
        }

        mat-form-field {
          font-size: 1.3em;
          padding-bottom: 0;
          input {
            padding-bottom: 0.5em;
            padding-left: 0.5em;
          }
        }

        button {
          // background: lighten($light-blue-text, 20%);
          color: #fff !important;
          font-size: 1.8em;
          text-transform: uppercase;
          margin: 1em 0 0.5em;
          letter-spacing: 1px;
          &:hover:not(:disabled) {
            background: linear-gradient(
              to right,
              $light-blue-text,
              $dark-blue-text
            );

            transition: all 0.5s ease-in;
          }
        }
      }

      &__welcome-back {
        font-size: 1.5em;
        // color: rgba($light-blue-text, 0.75);
        letter-spacing: 1px;
      }

      &__sign-up {
        font-size: 1.3em;

        a {
          font-size: 1em;
          cursor: pointer;
        }
      }
    }
  }
}
