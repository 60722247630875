@mixin dashboard-header-theme($theme) {
  app-dashboard-header {
    .dashboard-header {
      padding: 1em;
      box-shadow: 1px 1px 2px 2px rgba(mat-color($mat-primary, 200), 0.25);
      background-color: #fff;
      border-bottom: 1px solid #ddd;

      .flag-icon {
        font-size: 3em;
      }

      .userMenu {
        font-size: 1.4em;
        letter-spacing: 1px;
        cursor: pointer;
      }

      figure {
        margin: 0 1em;
        padding: 0;
        height: 40px;
        width: 40px;

        img {
          height: 100%;
          width: 100%;
          padding: 2px;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
          border: 0px solid rgba(mat-color($mat-primary, 200), 0.75);
        }
      }
    }
  }
}
.edura-logo{
  width: 75px;
  margin: 0px 10px;
  vertical-align: middle;
  margin-top: -8px;
}
