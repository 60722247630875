@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;600;700&display=swap");

html[dir="rtl"] {
  direction: rtl;
  text-align: right;
  font-family: Cairo;

  * > * {
    letter-spacing: initial !important;
  }
  // mat-drawer-content{
  //   margin-left: 0 !important;
  //   margin-right: 250px;
  // }
  app-website-footer {
    .website-footer {
      li {
        span {
          margin-right: 0;
          margin-left: 0.5em;
        }
      }
    }
  }

  app-website-home {
    .website-home {
      &__main-section {
        &__intro {
          &__try-now {
            padding: 0.7em 2.3em;
          }
        }
      }

      &__cards {
        div {
          &:not(:last-of-type) {
            margin-right: 0;
            margin-left: 1.7em;
            @media screen and (max-width: 599px) {
              margin: 1em 1.7em;
            }
          }
        }
      }

      &__learning {
        &__item {
          &__bottom-circle {
            span {
              padding: 0.8em;
            }
          }
        }
      }

      &__features {
        &__question-bank {
          margin-right: 0;
          margin-left: 3em;

          @media screen and (max-width: 599px) {
            margin-left: 0 !important;
          }
        }

        &__electronic-exam {
          margin-right: 0;
          margin-left: 3em;

          @media screen and (max-width: 599px) {
            margin-left: 0 !important;
          }
        }

        &__electronic-library {
          margin-right: 0;
          margin-left: 3em;

          @media screen and (max-width: 599px) {
            margin-left: 0 !important;
          }
        }

        &__electronic-store {
          margin-right: 0;
          margin-left: 3em;

          @media screen and (max-width: 599px) {
            margin-left: 0 !important;
          }
        }

        &__edura-programs {
          margin-right: auto;
          margin-left: 0;

          @media screen and (max-width: 599px) {
            margin-left: 0 !important;
          }
        }
      }

      &__video {
        h2 {
          margin-left: 2em;
          margin-right: 0 !important;
        }
      }
    }
  }

  .roles {
    &__card {
      justify-content: flex-start !important;
      h4 {
        font-size: 2em !important;
        margin-bottom: 0.75em !important;
      }
    }
  }
}
