@import "./assets/scss/normalize";
@import "./assets/scss/material";
@import "app/app.theme";
@import "./assets/scss/rtl";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
@import "~bootstrap/dist/css/bootstrap.css";


$primary-colour: var(--dynamic-primary-colour);
$secondary-colour: var(--dynamic-secondary-colour);

.bg-primary-colour {
  background-color: $primary-colour;
}
.bg-secondary-colour {
  background-color: $secondary-colour;
}

.text-primary-colour {
  color: $primary-colour;
}
.text-secondary-colour {
  color: $secondary-colour;
}

$MainColor: #1fb7d5;
$twoColor: #3d4fa1;

html[dir="ltr"] {
  mat-drawer-content {
    margin-right: 0 !important;
  }
}

html[dir="rtl"] {
  direction: rtl;
  text-align: right;
  font-family: Cairo;
  overflow: auto;

  mat-drawer-content {
    margin-left: 0 !important;
    margin-right: 250px;
  }
}
body {
  padding: 0;
  margin: 0;
  overflow: auto;
  font-family: "Roboto", sans-serif;
 
  overflow: auto !important;
 
  // background-color: $primary-colour;
 
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
button,
a,
div {
  font-family: "Roboto", sans-serif;
}
*,
:after,
:before {
  box-sizing: border-box;
}
.mx-15 {
  margin: 15px 0;
}
@media screen and (max-width: 768px) {
  mat-drawer {
    visibility: hidden;
  }
  mat-drawer-content {
    margin-left: 0;
  }
}
.first-char {
  width: 35px;
  height: 34px;
  border-radius: 50%;
  background: #d1eaef;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #229bb3;
}

mat-radio-group mat-radio-button .mat-radio-label-content {
  margin: 0 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
/*===  page title  =====  =*/

.page-title {
  h3 {
    color: #333;
    font-size: 22px;
    margin: 0;
    padding: 10px 0;
  }
}
/*===  page title  =====  =*/
.card-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #eee;

  .btn-add {
    margin: 0;
    // background: #1fb7d5;
    color: #fff;
    box-shadow: none;
    font-size: 14px;
    border-radius: 0px;
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0;
    padding: 0;
  }
}
/*===  card-data  =====  =*/

.card-data {
  background: #fff;
  display: flex;
  justify-content: space-around;
  padding: 0px;
  margin: 0px 5px 15px;
  color: #777;
  border-radius: 0px;
  align-items: center;
  box-shadow: 0 2px 4px rgb(15 34 58 / 12%);
  // border-top:4px solid #2fc1dc;

  h3 {
    font-size: 28px;
    margin: 15px 0 0;
  }
  p {
    font-size: 18px;
    margin: 15px 0;
  }
  mat-icon {
    font-size: 26px;
    display: initial;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 0;
    padding: 10px;
  }
}
/*===  mat-tab mat-tab=====  =*/
.mat-tab-labels {
  display: flex;
  // background: #cbedf3;
  border-radius: 0px;
}
.mat-tab-label {
  opacity: 1 !important;
  height: 40px !important;
  .mat-tab-label-content {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    color: #777;
    font-size: 14px;
    opacity: 1;
  }
}
.mat-tab-label-active {
  border-radius: 0px;
  // background: $MainColor;
  border-bottom: 3px solid #3d4fa1;
  .mat-tab-label-content {
    color: #3d4fa1;
    font-weight: 600;
  }
}
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: transparent !important;
  height: 0px;
}

/* ==========  Table =================== */
.matrial-table {
  padding: 1px;
}
mat-row {
  min-height: 32px;
}
.mat-cell {
  color: #777;
  font-size: 14px;
  p {
    padding: 0;
    margin: 0;
  }
}
mat-header-row {
 
  min-height: 55px !important;
  background: #3d4fa1;
  color: #fff !important;
 
  min-height: 42px !important;
  background: #e6e6ec;
  color: #000 !important;
 
  border-radius: 0px;
}
.mat-header-cell {
  color: #000;
  font-size: 14px;
}

mat-radio-group {
  font-size: 1.2em;
  padding: 10px;
}
.mat-elevation-z8 {
  box-shadow: none;
}

tr.mat-header-row {
  background-color: #3d4fa1 !important;
  border-radius: 0 0 0 !important;
}

#zmmtg-root {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent !important;
  bottom: 0 !important;
  right: 0 !important;
  z-index: 99 !important;
}

.full-width-field {
  width: 100%;
}

.timepicker__main-content {
  direction: initial !important;
}
