@mixin dashboard-theme($theme) {
  :host {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  mat-drawer-container {
    height: 100%;
  }

  mat-drawer-content {
    display: flex;
    flex-direction: column;
    background-color: rgba(gray, 0.1);
    overflow: auto;
    .content {
      padding: 1em 2em;
      // background: #e8edef;
      background: #eee;
    }
  }

  mat-drawer {
    // background-color: rgba(mat-color($mat-primary, 200), 0.5) !important;#3d4fa1
    // background-color: #3d4fa1 !important;
    // background-color: #323e73 !important;
    width: 250px;
  }

  mat-select .mat-select-placeholder {
    padding-bottom: 0 !important;
    color: #fff;
  }

  // Mat table

  .table-wrapper {
    position: relative;

    mat-table {
      position: relative;
      border-radius: 10px 10px 0 0;

      mat-header-row {
        background-color: #e6e6ec !important;
        border-radius: 0 0 0 !important;
      }

      mat-header-cell {
        // color: #fff !important;
      }

      mat-row {
        transition: all 0.1s ease-out;
        outline: 0;
        &:hover {
          background-color: rgba(blue, 0.125);
          font-weight: bold;
        }
      }
    }
  }

  .no-data-found {
    margin: 0 auto;
    padding: 2em 0;
    width: 200px;
    height: 200px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  .actions {
    cursor: pointer;
    outline: 0;
    border: 0;
  }

  .actions-menu {
    .mat-menu-content {
      padding: 0 !important;
    }

    button:hover {
      color: #fff;
      background-color: mat-color($mat-primary, 200) !important;
      transition: all 0.5s ease-in;
    }
  }

  .label {
    &--success {
      color: mat-color($mat-primary, 200);
    }

    &--error {
      color: rgba(grey, 0.5);
    }
  }

  .status {
    border-radius: 5px;
    padding: 4px 8px;
    font-size: 0.9em;
    letter-spacing: 1px;
    font-weight: 700;

    &--active {
      color: rgba(green, 0.6);
    }

    &--block {
      color: darken(red, 10%);
    }

    &--suspended {
      color: rgba(grey, 0.5);
    }

    &--requested {
      color: mat-color($mat-primary, 200);
    }

    &--pending {
      color: rgba(mat-color($mat-primary, 200), 0.5);
    }

    &--not-paid {
      background-color: rgba(orange, 0.85);
    }
  }

  .table-image {
    padding: 2px;
    border: 1px solid mat-color($mat-primary);
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    object-fit: cover;
    object-position: center;
  }

  .attachment-image {
    padding: 3px;
    border: 1px solid mat-color($mat-primary);
    width: 75px;
    height: 75px;
    border-radius: 15px;
    cursor: pointer;
    margin: 0 10px;

    img {
      border-radius: 15px;

      width: 100%;
      height: 100%;
    }
  }

  .attachment-video {
    color: rgba(black, 0.75);
    font-size: 90px;
    width: 90px;
    height: 90px;
    margin: 0 10px;
  }

  .attachment-text {
    color: rgba(red, 0.75);
    font-size: 90px;
    width: 90px;
    height: 90px;
    margin: 0 10px;
    cursor: pointer;
  }

  .course-hours {
    display: inline-block;
    background-color: rgba(blue, 0.5);
    padding: 0.5em 1em;
    border-radius: 15px;
    color: #fff;
    margin: 5px;
  }

  .breadcrumbs__container {
    display: inline-block;
    list-style-type: none;
    margin: 2em 0;
    padding: 0;
    letter-spacing: 1px;

    .breadcrumbs__item {
      margin: 0 0.5em;
      padding: 0px;
      display: inline-block;
      font-size: 2em;
      font-weight: bold;
      border-radius: 0;
      background-color: #fff;
      cursor: pointer;
      border: 0px solid mat-color($mat-primary, 200);
      transition: all 500ms ease-in;
      a {
        text-decoration: none;
        width: 100%;
        height: 100%;
      }

      &:hover {
        color: #fff;
        background-color: mat-color($mat-primary, 200);
      }
    }

    .breadcrumbs__item--active {
      color: #333;
      background-color: #fff;
      a {
        text-decoration: none !important;
      }
    }
  }

  .timer {
    text-align: center;
    font-size: 3em;
    margin: 1em 0;
    color: rgba(red, 0.75);
    font-weight: bold;
  }
}
