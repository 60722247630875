@mixin website-header-mobile-theme($theme) {
  app-website-header-mobile {
    .website-header-mobile {
      background-color: rgba($light-blue-text, 0.03);
      &__wrapper {
        padding: 1rem;
      }

      mat-icon {
        color: $dark-text;
        font-size: 30px;
      }

      mat-sidenav {
        width: 100%;
      }

      mat-nav-list {
        width: 100%;
        height: 100%;
        a {
          font-size: 1.5rem;
          font-weight: bolder;
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}
