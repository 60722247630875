@mixin dashboard-sidebar-theme($theme) {
  app-dashboard-sidebar {
    .dashboard-sidebar {
      color: #fff;
      &__logo {
        cursor: pointer;
        outline: 0;
        height: 48px;

        img {
          height: 100%;
          width: 100%;
        }
      }

      &__profile {
        width: 60px;
        height: 60px;
        img {
          height: 100%;
          width: 100%;
          border-radius: 50%;
          border: 0px solid #fff;
          padding: 0px;
          object-position: center;
          object-fit: cover;
        }
      }

      ul {
        list-style-type: none;
        margin: 1em 0 0;
        padding: 0;
        width: 100%;

        li {
          margin: 0px 8px;
          border-radius: 0px;

          mat-accordion {
            mat-expansion-panel {
              box-shadow: none !important;
              color: #fff;
              background-color: transparent;
              padding-bottom: 0;

              a {
                padding: 1em 0;
              }

              mat-expansion-panel-header {
                width: 100%;
                padding: 0;

                mat-panel-title {
                  color: #fff;
                }
              }

              .mat-expansion-panel-body {
                padding-bottom: 0;
              }
            }
          }

          &.expansion-list {
            &:hover {
              background-color: rgba(#3d4fa1, 0.2) !important;
            }
            width: 100%;
            padding: 0 0.5em;

            .expansion-item {
              width: 100%;

              &:hover {
                background-color: mat-color($mat-primary, 200);
              }
            }
          }

          padding: 8px;
          cursor: pointer;
          letter-spacing: 1px;
          font-size: 14px;
          appearance: none;
          outline: none;

          a {
            text-decoration: none;
            color: inherit;
          }

          mat-icon {
            margin: 0 0.5em;
            font-size: 20px;
          }

          &:hover {
            // background-color: mat-color($mat-primary, 200);
            // transition: all 0.3s ease-in;
            // background-color: rgb(255 255 255 / 25%);
            transition: all 0.3s ease-in;
            background-color: #fff;
            transition: all 0.3s ease-in;
            color: #323e73;
          }
        }
        .active-item {
          background-color: #fff;
          transition: all 0.3s ease-in;
          color: #323e73;
        }
      }

      h4 {
        margin-bottom: 0;
        font-size: 14px;
        margin: 0;
        padding: 15px 5px 5px;
      }

      p {
        color: #fff;
        padding: 5px 5px 0px;
        margin: 0;
        font-size: 12px;
      }

      img {
        width: 100%;
        height: 100%;
      }

      figure {
        padding: 0;
        margin: 5px 10px;
      }
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(mat-color($mat-primary, 200), 0.5);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(mat-color($mat-primary, 200), 0.8);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(mat-color($mat-primary, darker), 0.75);
  }

  .active-item {
    background-color: mat-color($mat-primary, 200);
  }
}
.user-info {
  // background: #323e73;
  width: 100%;
  text-align: start;
  padding: 15px 5px;
  display: flex;
  border-bottom: 1px solid #636b90;
}
