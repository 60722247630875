@charset "UTF-8";
@import "https://fonts.googleapis.com/css?family=Material+Icons";
@import url("https://fonts.googleapis.com/css?family=Muli:300,400,500");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
@import "~bootstrap/dist/css/bootstrap.css";
.mat-button-loading .mat-button-wrapper {
  visibility: hidden;
}
.mat-button-loading .mat-progress-spinner {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
}

/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
  	This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
  	calculation of the height of the owl-item that breaks page layouts
   */
}
.owl-carousel .owl-item .owl-lazy {
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

.flag-icon-background, .flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.3333333333em;
  line-height: 1em;
}
.flag-icon:before {
  content: " ";
}
.flag-icon.flag-icon-squared {
  width: 1em;
}

.flag-icon-ad {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ad.svg);
}
.flag-icon-ad.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ad.svg);
}

.flag-icon-ae {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ae.svg);
}
.flag-icon-ae.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ae.svg);
}

.flag-icon-af {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/af.svg);
}
.flag-icon-af.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/af.svg);
}

.flag-icon-ag {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ag.svg);
}
.flag-icon-ag.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ag.svg);
}

.flag-icon-ai {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ai.svg);
}
.flag-icon-ai.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ai.svg);
}

.flag-icon-al {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/al.svg);
}
.flag-icon-al.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/al.svg);
}

.flag-icon-am {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/am.svg);
}
.flag-icon-am.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/am.svg);
}

.flag-icon-ao {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ao.svg);
}
.flag-icon-ao.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ao.svg);
}

.flag-icon-aq {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/aq.svg);
}
.flag-icon-aq.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/aq.svg);
}

.flag-icon-ar {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ar.svg);
}
.flag-icon-ar.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ar.svg);
}

.flag-icon-as {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/as.svg);
}
.flag-icon-as.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/as.svg);
}

.flag-icon-at {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/at.svg);
}
.flag-icon-at.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/at.svg);
}

.flag-icon-au {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/au.svg);
}
.flag-icon-au.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/au.svg);
}

.flag-icon-aw {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/aw.svg);
}
.flag-icon-aw.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/aw.svg);
}

.flag-icon-ax {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ax.svg);
}
.flag-icon-ax.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ax.svg);
}

.flag-icon-az {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/az.svg);
}
.flag-icon-az.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/az.svg);
}

.flag-icon-ba {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ba.svg);
}
.flag-icon-ba.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ba.svg);
}

.flag-icon-bb {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/bb.svg);
}
.flag-icon-bb.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/bb.svg);
}

.flag-icon-bd {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/bd.svg);
}
.flag-icon-bd.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/bd.svg);
}

.flag-icon-be {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/be.svg);
}
.flag-icon-be.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/be.svg);
}

.flag-icon-bf {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/bf.svg);
}
.flag-icon-bf.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/bf.svg);
}

.flag-icon-bg {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/bg.svg);
}
.flag-icon-bg.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/bg.svg);
}

.flag-icon-bh {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/bh.svg);
}
.flag-icon-bh.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/bh.svg);
}

.flag-icon-bi {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/bi.svg);
}
.flag-icon-bi.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/bi.svg);
}

.flag-icon-bj {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/bj.svg);
}
.flag-icon-bj.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/bj.svg);
}

.flag-icon-bl {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/bl.svg);
}
.flag-icon-bl.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/bl.svg);
}

.flag-icon-bm {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/bm.svg);
}
.flag-icon-bm.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/bm.svg);
}

.flag-icon-bn {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/bn.svg);
}
.flag-icon-bn.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/bn.svg);
}

.flag-icon-bo {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/bo.svg);
}
.flag-icon-bo.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/bo.svg);
}

.flag-icon-bq {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/bq.svg);
}
.flag-icon-bq.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/bq.svg);
}

.flag-icon-br {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/br.svg);
}
.flag-icon-br.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/br.svg);
}

.flag-icon-bs {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/bs.svg);
}
.flag-icon-bs.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/bs.svg);
}

.flag-icon-bt {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/bt.svg);
}
.flag-icon-bt.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/bt.svg);
}

.flag-icon-bv {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/bv.svg);
}
.flag-icon-bv.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/bv.svg);
}

.flag-icon-bw {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/bw.svg);
}
.flag-icon-bw.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/bw.svg);
}

.flag-icon-by {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/by.svg);
}
.flag-icon-by.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/by.svg);
}

.flag-icon-bz {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/bz.svg);
}
.flag-icon-bz.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/bz.svg);
}

.flag-icon-ca {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ca.svg);
}
.flag-icon-ca.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ca.svg);
}

.flag-icon-cc {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/cc.svg);
}
.flag-icon-cc.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/cc.svg);
}

.flag-icon-cd {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/cd.svg);
}
.flag-icon-cd.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/cd.svg);
}

.flag-icon-cf {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/cf.svg);
}
.flag-icon-cf.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/cf.svg);
}

.flag-icon-cg {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/cg.svg);
}
.flag-icon-cg.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/cg.svg);
}

.flag-icon-ch {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ch.svg);
}
.flag-icon-ch.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ch.svg);
}

.flag-icon-ci {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ci.svg);
}
.flag-icon-ci.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ci.svg);
}

.flag-icon-ck {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ck.svg);
}
.flag-icon-ck.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ck.svg);
}

.flag-icon-cl {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/cl.svg);
}
.flag-icon-cl.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/cl.svg);
}

.flag-icon-cm {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/cm.svg);
}
.flag-icon-cm.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/cm.svg);
}

.flag-icon-cn {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/cn.svg);
}
.flag-icon-cn.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/cn.svg);
}

.flag-icon-co {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/co.svg);
}
.flag-icon-co.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/co.svg);
}

.flag-icon-cr {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/cr.svg);
}
.flag-icon-cr.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/cr.svg);
}

.flag-icon-cu {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/cu.svg);
}
.flag-icon-cu.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/cu.svg);
}

.flag-icon-cv {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/cv.svg);
}
.flag-icon-cv.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/cv.svg);
}

.flag-icon-cw {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/cw.svg);
}
.flag-icon-cw.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/cw.svg);
}

.flag-icon-cx {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/cx.svg);
}
.flag-icon-cx.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/cx.svg);
}

.flag-icon-cy {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/cy.svg);
}
.flag-icon-cy.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/cy.svg);
}

.flag-icon-cz {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/cz.svg);
}
.flag-icon-cz.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/cz.svg);
}

.flag-icon-de {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/de.svg);
}
.flag-icon-de.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/de.svg);
}

.flag-icon-dj {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/dj.svg);
}
.flag-icon-dj.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/dj.svg);
}

.flag-icon-dk {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/dk.svg);
}
.flag-icon-dk.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/dk.svg);
}

.flag-icon-dm {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/dm.svg);
}
.flag-icon-dm.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/dm.svg);
}

.flag-icon-do {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/do.svg);
}
.flag-icon-do.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/do.svg);
}

.flag-icon-dz {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/dz.svg);
}
.flag-icon-dz.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/dz.svg);
}

.flag-icon-ec {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ec.svg);
}
.flag-icon-ec.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ec.svg);
}

.flag-icon-ee {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ee.svg);
}
.flag-icon-ee.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ee.svg);
}

.flag-icon-eg {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/eg.svg);
}
.flag-icon-eg.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/eg.svg);
}

.flag-icon-eh {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/eh.svg);
}
.flag-icon-eh.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/eh.svg);
}

.flag-icon-er {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/er.svg);
}
.flag-icon-er.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/er.svg);
}

.flag-icon-es {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/es.svg);
}
.flag-icon-es.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/es.svg);
}

.flag-icon-et {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/et.svg);
}
.flag-icon-et.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/et.svg);
}

.flag-icon-fi {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/fi.svg);
}
.flag-icon-fi.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/fi.svg);
}

.flag-icon-fj {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/fj.svg);
}
.flag-icon-fj.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/fj.svg);
}

.flag-icon-fk {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/fk.svg);
}
.flag-icon-fk.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/fk.svg);
}

.flag-icon-fm {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/fm.svg);
}
.flag-icon-fm.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/fm.svg);
}

.flag-icon-fo {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/fo.svg);
}
.flag-icon-fo.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/fo.svg);
}

.flag-icon-fr {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/fr.svg);
}
.flag-icon-fr.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/fr.svg);
}

.flag-icon-ga {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ga.svg);
}
.flag-icon-ga.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ga.svg);
}

.flag-icon-gb {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/gb.svg);
}
.flag-icon-gb.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/gb.svg);
}

.flag-icon-gd {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/gd.svg);
}
.flag-icon-gd.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/gd.svg);
}

.flag-icon-ge {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ge.svg);
}
.flag-icon-ge.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ge.svg);
}

.flag-icon-gf {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/gf.svg);
}
.flag-icon-gf.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/gf.svg);
}

.flag-icon-gg {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/gg.svg);
}
.flag-icon-gg.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/gg.svg);
}

.flag-icon-gh {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/gh.svg);
}
.flag-icon-gh.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/gh.svg);
}

.flag-icon-gi {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/gi.svg);
}
.flag-icon-gi.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/gi.svg);
}

.flag-icon-gl {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/gl.svg);
}
.flag-icon-gl.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/gl.svg);
}

.flag-icon-gm {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/gm.svg);
}
.flag-icon-gm.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/gm.svg);
}

.flag-icon-gn {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/gn.svg);
}
.flag-icon-gn.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/gn.svg);
}

.flag-icon-gp {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/gp.svg);
}
.flag-icon-gp.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/gp.svg);
}

.flag-icon-gq {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/gq.svg);
}
.flag-icon-gq.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/gq.svg);
}

.flag-icon-gr {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/gr.svg);
}
.flag-icon-gr.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/gr.svg);
}

.flag-icon-gs {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/gs.svg);
}
.flag-icon-gs.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/gs.svg);
}

.flag-icon-gt {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/gt.svg);
}
.flag-icon-gt.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/gt.svg);
}

.flag-icon-gu {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/gu.svg);
}
.flag-icon-gu.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/gu.svg);
}

.flag-icon-gw {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/gw.svg);
}
.flag-icon-gw.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/gw.svg);
}

.flag-icon-gy {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/gy.svg);
}
.flag-icon-gy.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/gy.svg);
}

.flag-icon-hk {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/hk.svg);
}
.flag-icon-hk.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/hk.svg);
}

.flag-icon-hm {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/hm.svg);
}
.flag-icon-hm.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/hm.svg);
}

.flag-icon-hn {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/hn.svg);
}
.flag-icon-hn.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/hn.svg);
}

.flag-icon-hr {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/hr.svg);
}
.flag-icon-hr.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/hr.svg);
}

.flag-icon-ht {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ht.svg);
}
.flag-icon-ht.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ht.svg);
}

.flag-icon-hu {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/hu.svg);
}
.flag-icon-hu.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/hu.svg);
}

.flag-icon-id {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/id.svg);
}
.flag-icon-id.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/id.svg);
}

.flag-icon-ie {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ie.svg);
}
.flag-icon-ie.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ie.svg);
}

.flag-icon-il {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/il.svg);
}
.flag-icon-il.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/il.svg);
}

.flag-icon-im {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/im.svg);
}
.flag-icon-im.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/im.svg);
}

.flag-icon-in {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/in.svg);
}
.flag-icon-in.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/in.svg);
}

.flag-icon-io {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/io.svg);
}
.flag-icon-io.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/io.svg);
}

.flag-icon-iq {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/iq.svg);
}
.flag-icon-iq.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/iq.svg);
}

.flag-icon-ir {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ir.svg);
}
.flag-icon-ir.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ir.svg);
}

.flag-icon-is {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/is.svg);
}
.flag-icon-is.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/is.svg);
}

.flag-icon-it {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/it.svg);
}
.flag-icon-it.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/it.svg);
}

.flag-icon-je {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/je.svg);
}
.flag-icon-je.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/je.svg);
}

.flag-icon-jm {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/jm.svg);
}
.flag-icon-jm.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/jm.svg);
}

.flag-icon-jo {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/jo.svg);
}
.flag-icon-jo.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/jo.svg);
}

.flag-icon-jp {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/jp.svg);
}
.flag-icon-jp.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/jp.svg);
}

.flag-icon-ke {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ke.svg);
}
.flag-icon-ke.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ke.svg);
}

.flag-icon-kg {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/kg.svg);
}
.flag-icon-kg.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/kg.svg);
}

.flag-icon-kh {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/kh.svg);
}
.flag-icon-kh.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/kh.svg);
}

.flag-icon-ki {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ki.svg);
}
.flag-icon-ki.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ki.svg);
}

.flag-icon-km {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/km.svg);
}
.flag-icon-km.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/km.svg);
}

.flag-icon-kn {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/kn.svg);
}
.flag-icon-kn.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/kn.svg);
}

.flag-icon-kp {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/kp.svg);
}
.flag-icon-kp.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/kp.svg);
}

.flag-icon-kr {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/kr.svg);
}
.flag-icon-kr.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/kr.svg);
}

.flag-icon-kw {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/kw.svg);
}
.flag-icon-kw.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/kw.svg);
}

.flag-icon-ky {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ky.svg);
}
.flag-icon-ky.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ky.svg);
}

.flag-icon-kz {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/kz.svg);
}
.flag-icon-kz.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/kz.svg);
}

.flag-icon-la {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/la.svg);
}
.flag-icon-la.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/la.svg);
}

.flag-icon-lb {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/lb.svg);
}
.flag-icon-lb.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/lb.svg);
}

.flag-icon-lc {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/lc.svg);
}
.flag-icon-lc.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/lc.svg);
}

.flag-icon-li {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/li.svg);
}
.flag-icon-li.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/li.svg);
}

.flag-icon-lk {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/lk.svg);
}
.flag-icon-lk.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/lk.svg);
}

.flag-icon-lr {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/lr.svg);
}
.flag-icon-lr.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/lr.svg);
}

.flag-icon-ls {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ls.svg);
}
.flag-icon-ls.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ls.svg);
}

.flag-icon-lt {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/lt.svg);
}
.flag-icon-lt.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/lt.svg);
}

.flag-icon-lu {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/lu.svg);
}
.flag-icon-lu.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/lu.svg);
}

.flag-icon-lv {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/lv.svg);
}
.flag-icon-lv.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/lv.svg);
}

.flag-icon-ly {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ly.svg);
}
.flag-icon-ly.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ly.svg);
}

.flag-icon-ma {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ma.svg);
}
.flag-icon-ma.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ma.svg);
}

.flag-icon-mc {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/mc.svg);
}
.flag-icon-mc.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/mc.svg);
}

.flag-icon-md {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/md.svg);
}
.flag-icon-md.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/md.svg);
}

.flag-icon-me {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/me.svg);
}
.flag-icon-me.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/me.svg);
}

.flag-icon-mf {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/mf.svg);
}
.flag-icon-mf.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/mf.svg);
}

.flag-icon-mg {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/mg.svg);
}
.flag-icon-mg.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/mg.svg);
}

.flag-icon-mh {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/mh.svg);
}
.flag-icon-mh.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/mh.svg);
}

.flag-icon-mk {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/mk.svg);
}
.flag-icon-mk.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/mk.svg);
}

.flag-icon-ml {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ml.svg);
}
.flag-icon-ml.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ml.svg);
}

.flag-icon-mm {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/mm.svg);
}
.flag-icon-mm.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/mm.svg);
}

.flag-icon-mn {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/mn.svg);
}
.flag-icon-mn.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/mn.svg);
}

.flag-icon-mo {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/mo.svg);
}
.flag-icon-mo.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/mo.svg);
}

.flag-icon-mp {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/mp.svg);
}
.flag-icon-mp.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/mp.svg);
}

.flag-icon-mq {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/mq.svg);
}
.flag-icon-mq.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/mq.svg);
}

.flag-icon-mr {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/mr.svg);
}
.flag-icon-mr.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/mr.svg);
}

.flag-icon-ms {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ms.svg);
}
.flag-icon-ms.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ms.svg);
}

.flag-icon-mt {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/mt.svg);
}
.flag-icon-mt.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/mt.svg);
}

.flag-icon-mu {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/mu.svg);
}
.flag-icon-mu.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/mu.svg);
}

.flag-icon-mv {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/mv.svg);
}
.flag-icon-mv.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/mv.svg);
}

.flag-icon-mw {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/mw.svg);
}
.flag-icon-mw.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/mw.svg);
}

.flag-icon-mx {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/mx.svg);
}
.flag-icon-mx.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/mx.svg);
}

.flag-icon-my {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/my.svg);
}
.flag-icon-my.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/my.svg);
}

.flag-icon-mz {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/mz.svg);
}
.flag-icon-mz.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/mz.svg);
}

.flag-icon-na {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/na.svg);
}
.flag-icon-na.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/na.svg);
}

.flag-icon-nc {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/nc.svg);
}
.flag-icon-nc.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/nc.svg);
}

.flag-icon-ne {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ne.svg);
}
.flag-icon-ne.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ne.svg);
}

.flag-icon-nf {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/nf.svg);
}
.flag-icon-nf.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/nf.svg);
}

.flag-icon-ng {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ng.svg);
}
.flag-icon-ng.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ng.svg);
}

.flag-icon-ni {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ni.svg);
}
.flag-icon-ni.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ni.svg);
}

.flag-icon-nl {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/nl.svg);
}
.flag-icon-nl.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/nl.svg);
}

.flag-icon-no {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/no.svg);
}
.flag-icon-no.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/no.svg);
}

.flag-icon-np {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/np.svg);
}
.flag-icon-np.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/np.svg);
}

.flag-icon-nr {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/nr.svg);
}
.flag-icon-nr.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/nr.svg);
}

.flag-icon-nu {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/nu.svg);
}
.flag-icon-nu.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/nu.svg);
}

.flag-icon-nz {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/nz.svg);
}
.flag-icon-nz.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/nz.svg);
}

.flag-icon-om {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/om.svg);
}
.flag-icon-om.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/om.svg);
}

.flag-icon-pa {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/pa.svg);
}
.flag-icon-pa.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/pa.svg);
}

.flag-icon-pe {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/pe.svg);
}
.flag-icon-pe.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/pe.svg);
}

.flag-icon-pf {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/pf.svg);
}
.flag-icon-pf.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/pf.svg);
}

.flag-icon-pg {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/pg.svg);
}
.flag-icon-pg.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/pg.svg);
}

.flag-icon-ph {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ph.svg);
}
.flag-icon-ph.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ph.svg);
}

.flag-icon-pk {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/pk.svg);
}
.flag-icon-pk.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/pk.svg);
}

.flag-icon-pl {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/pl.svg);
}
.flag-icon-pl.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/pl.svg);
}

.flag-icon-pm {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/pm.svg);
}
.flag-icon-pm.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/pm.svg);
}

.flag-icon-pn {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/pn.svg);
}
.flag-icon-pn.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/pn.svg);
}

.flag-icon-pr {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/pr.svg);
}
.flag-icon-pr.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/pr.svg);
}

.flag-icon-ps {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ps.svg);
}
.flag-icon-ps.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ps.svg);
}

.flag-icon-pt {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/pt.svg);
}
.flag-icon-pt.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/pt.svg);
}

.flag-icon-pw {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/pw.svg);
}
.flag-icon-pw.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/pw.svg);
}

.flag-icon-py {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/py.svg);
}
.flag-icon-py.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/py.svg);
}

.flag-icon-qa {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/qa.svg);
}
.flag-icon-qa.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/qa.svg);
}

.flag-icon-re {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/re.svg);
}
.flag-icon-re.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/re.svg);
}

.flag-icon-ro {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ro.svg);
}
.flag-icon-ro.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ro.svg);
}

.flag-icon-rs {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/rs.svg);
}
.flag-icon-rs.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/rs.svg);
}

.flag-icon-ru {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ru.svg);
}
.flag-icon-ru.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ru.svg);
}

.flag-icon-rw {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/rw.svg);
}
.flag-icon-rw.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/rw.svg);
}

.flag-icon-sa {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/sa.svg);
}
.flag-icon-sa.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/sa.svg);
}

.flag-icon-sb {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/sb.svg);
}
.flag-icon-sb.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/sb.svg);
}

.flag-icon-sc {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/sc.svg);
}
.flag-icon-sc.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/sc.svg);
}

.flag-icon-sd {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/sd.svg);
}
.flag-icon-sd.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/sd.svg);
}

.flag-icon-se {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/se.svg);
}
.flag-icon-se.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/se.svg);
}

.flag-icon-sg {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/sg.svg);
}
.flag-icon-sg.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/sg.svg);
}

.flag-icon-sh {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/sh.svg);
}
.flag-icon-sh.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/sh.svg);
}

.flag-icon-si {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/si.svg);
}
.flag-icon-si.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/si.svg);
}

.flag-icon-sj {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/sj.svg);
}
.flag-icon-sj.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/sj.svg);
}

.flag-icon-sk {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/sk.svg);
}
.flag-icon-sk.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/sk.svg);
}

.flag-icon-sl {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/sl.svg);
}
.flag-icon-sl.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/sl.svg);
}

.flag-icon-sm {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/sm.svg);
}
.flag-icon-sm.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/sm.svg);
}

.flag-icon-sn {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/sn.svg);
}
.flag-icon-sn.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/sn.svg);
}

.flag-icon-so {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/so.svg);
}
.flag-icon-so.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/so.svg);
}

.flag-icon-sr {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/sr.svg);
}
.flag-icon-sr.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/sr.svg);
}

.flag-icon-ss {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ss.svg);
}
.flag-icon-ss.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ss.svg);
}

.flag-icon-st {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/st.svg);
}
.flag-icon-st.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/st.svg);
}

.flag-icon-sv {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/sv.svg);
}
.flag-icon-sv.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/sv.svg);
}

.flag-icon-sx {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/sx.svg);
}
.flag-icon-sx.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/sx.svg);
}

.flag-icon-sy {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/sy.svg);
}
.flag-icon-sy.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/sy.svg);
}

.flag-icon-sz {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/sz.svg);
}
.flag-icon-sz.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/sz.svg);
}

.flag-icon-tc {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/tc.svg);
}
.flag-icon-tc.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/tc.svg);
}

.flag-icon-td {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/td.svg);
}
.flag-icon-td.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/td.svg);
}

.flag-icon-tf {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/tf.svg);
}
.flag-icon-tf.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/tf.svg);
}

.flag-icon-tg {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/tg.svg);
}
.flag-icon-tg.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/tg.svg);
}

.flag-icon-th {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/th.svg);
}
.flag-icon-th.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/th.svg);
}

.flag-icon-tj {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/tj.svg);
}
.flag-icon-tj.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/tj.svg);
}

.flag-icon-tk {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/tk.svg);
}
.flag-icon-tk.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/tk.svg);
}

.flag-icon-tl {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/tl.svg);
}
.flag-icon-tl.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/tl.svg);
}

.flag-icon-tm {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/tm.svg);
}
.flag-icon-tm.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/tm.svg);
}

.flag-icon-tn {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/tn.svg);
}
.flag-icon-tn.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/tn.svg);
}

.flag-icon-to {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/to.svg);
}
.flag-icon-to.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/to.svg);
}

.flag-icon-tr {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/tr.svg);
}
.flag-icon-tr.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/tr.svg);
}

.flag-icon-tt {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/tt.svg);
}
.flag-icon-tt.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/tt.svg);
}

.flag-icon-tv {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/tv.svg);
}
.flag-icon-tv.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/tv.svg);
}

.flag-icon-tw {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/tw.svg);
}
.flag-icon-tw.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/tw.svg);
}

.flag-icon-tz {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/tz.svg);
}
.flag-icon-tz.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/tz.svg);
}

.flag-icon-ua {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ua.svg);
}
.flag-icon-ua.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ua.svg);
}

.flag-icon-ug {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ug.svg);
}
.flag-icon-ug.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ug.svg);
}

.flag-icon-um {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/um.svg);
}
.flag-icon-um.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/um.svg);
}

.flag-icon-us {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/us.svg);
}
.flag-icon-us.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/us.svg);
}

.flag-icon-uy {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/uy.svg);
}
.flag-icon-uy.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/uy.svg);
}

.flag-icon-uz {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/uz.svg);
}
.flag-icon-uz.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/uz.svg);
}

.flag-icon-va {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/va.svg);
}
.flag-icon-va.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/va.svg);
}

.flag-icon-vc {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/vc.svg);
}
.flag-icon-vc.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/vc.svg);
}

.flag-icon-ve {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ve.svg);
}
.flag-icon-ve.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ve.svg);
}

.flag-icon-vg {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/vg.svg);
}
.flag-icon-vg.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/vg.svg);
}

.flag-icon-vi {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/vi.svg);
}
.flag-icon-vi.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/vi.svg);
}

.flag-icon-vn {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/vn.svg);
}
.flag-icon-vn.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/vn.svg);
}

.flag-icon-vu {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/vu.svg);
}
.flag-icon-vu.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/vu.svg);
}

.flag-icon-wf {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/wf.svg);
}
.flag-icon-wf.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/wf.svg);
}

.flag-icon-ws {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ws.svg);
}
.flag-icon-ws.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ws.svg);
}

.flag-icon-ye {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/ye.svg);
}
.flag-icon-ye.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/ye.svg);
}

.flag-icon-yt {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/yt.svg);
}
.flag-icon-yt.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/yt.svg);
}

.flag-icon-za {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/za.svg);
}
.flag-icon-za.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/za.svg);
}

.flag-icon-zm {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/zm.svg);
}
.flag-icon-zm.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/zm.svg);
}

.flag-icon-zw {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/zw.svg);
}
.flag-icon-zw.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/zw.svg);
}

.flag-icon-es-ca {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/es-ca.svg);
}
.flag-icon-es-ca.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/es-ca.svg);
}

.flag-icon-es-ga {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/es-ga.svg);
}
.flag-icon-es-ga.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/es-ga.svg);
}

.flag-icon-eu {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/eu.svg);
}
.flag-icon-eu.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/eu.svg);
}

.flag-icon-gb-eng {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/gb-eng.svg);
}
.flag-icon-gb-eng.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/gb-eng.svg);
}

.flag-icon-gb-nir {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/gb-nir.svg);
}
.flag-icon-gb-nir.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/gb-nir.svg);
}

.flag-icon-gb-sct {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/gb-sct.svg);
}
.flag-icon-gb-sct.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/gb-sct.svg);
}

.flag-icon-gb-wls {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/gb-wls.svg);
}
.flag-icon-gb-wls.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/gb-wls.svg);
}

.flag-icon-un {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/un.svg);
}
.flag-icon-un.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/un.svg);
}

.flag-icon-xk {
  background-image: url(../node_modules/flag-icon-css/flags/4x3/xk.svg);
}
.flag-icon-xk.flag-icon-squared {
  background-image: url(../node_modules/flag-icon-css/flags/1x1/xk.svg);
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Muli;
  letter-spacing: 0em;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Muli;
  letter-spacing: 0.0075em;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Muli;
  letter-spacing: 0.0094em;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 500 15px/24px Muli;
  letter-spacing: 0.0067em;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/20px Muli;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px Muli;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Muli;
  letter-spacing: 0.0179em;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Muli;
  letter-spacing: 0.0179em;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px/20px Muli;
  letter-spacing: 0.0333em;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Muli;
  letter-spacing: -0.0134em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Muli;
  letter-spacing: -0.0089em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Muli;
  letter-spacing: 0em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Muli;
  letter-spacing: 0.0074em;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px/20px Muli;
  letter-spacing: 0.0179em;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Muli;
  font-size: 14px;
  font-weight: 500;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px/32px Muli;
  letter-spacing: 0.0075em;
}

.mat-expansion-panel-header {
  font-family: Muli;
  font-size: 15px;
  font-weight: 500;
}

.mat-expansion-panel-content {
  font: 400 14px/20px Muli;
  letter-spacing: 0.0179em;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Muli;
  letter-spacing: 1.5px;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Muli;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Muli;
  font-size: 12px;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slider-thumb-label-text {
  font-size: 12px;
  font-weight: 500;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-label, .mat-tab-link {
  font-family: Muli;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Muli;
  letter-spacing: 0.0075em;
  margin: 0;
}

.mat-tooltip {
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Muli;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px/24px Muli;
  letter-spacing: 0.0179em;
}

.mat-simple-snackbar {
  font-family: Muli;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

html,
body {
  height: 100%;
}

[dir=ltr] {
  font-family: Muli;
}

body {
  font-size: 10px;
  --primary-color: #3d4fa1;
  --primary-lighter-color: #c5cae3;
  --primary-darker-color: #273685;
  --text-primary-color: #ffffff;
  --text-primary-lighter-color: rgba(61, 79, 161, 0.87);
  --text-primary-darker-color: #ffffff;
}

body {
  --accent-color: #db4488;
  --accent-lighter-color: #f4c7db;
  --accent-darker-color: #cd2d6b;
  --text-accent-color: #ffffff;
  --text-accent-lighter-color: rgba(61, 79, 161, 0.87);
  --text-accent-darker-color: #ffffff;
}

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #ffffff;
  --text-warn-lighter-color: rgba(61, 79, 161, 0.87);
  --text-warn-darker-color: #ffffff;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(61, 79, 161, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(44, 44, 44, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(44, 44, 44, 0.04);
}
.mat-option.mat-active {
  background: rgba(44, 44, 44, 0.04);
  color: rgba(61, 79, 161, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(61, 79, 161, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #3d4fa1;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #db4488;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: red;
}

.mat-optgroup-label {
  color: rgba(61, 79, 161, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(61, 79, 161, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(61, 79, 161, 0.54);
}
.mat-pseudo-checkbox::after {
  color: white;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #3d4fa1;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #db4488;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: red;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: white;
  color: rgba(61, 79, 161, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(61, 79, 161, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(61, 79, 161, 0.87);
}

.mat-badge-content {
  color: white;
  background: #3d4fa1;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #db4488;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: red;
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-disabled .mat-badge-content {
  background: #cdd1e7;
  color: rgba(61, 79, 161, 0.38);
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(61, 79, 161, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #3d4fa1;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #db4488;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: red;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(61, 79, 161, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #3d4fa1;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #db4488;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: red;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(61, 79, 161, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(61, 79, 161, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(61, 79, 161, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #3d4fa1;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #db4488;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: red;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(44, 44, 44, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(61, 79, 161, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(61, 79, 161, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(61, 79, 161, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: #3d4fa1;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(61, 79, 161, 0.12);
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(61, 79, 161, 0.12);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(61, 79, 161, 0.12);
}

.mat-button-toggle-checked {
  background-color: #cccccc;
  color: rgba(61, 79, 161, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(61, 79, 161, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(61, 79, 161, 0.26);
  background-color: #e6e6e6;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #b3b3b3;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(61, 79, 161, 0.12);
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(61, 79, 161, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(61, 79, 161, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(61, 79, 161, 0.54);
}

.mat-checkbox-checkmark {
  fill: white;
}

.mat-checkbox-checkmark-path {
  stroke: white !important;
}

.mat-checkbox-mixedmark {
  background-color: white;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #3d4fa1;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #db4488;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: red;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(61, 79, 161, 0.54);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #3d4fa1;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #db4488;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: red;
}

.mat-chip.mat-standard-chip {
  background-color: #e6e6e6;
  color: rgba(61, 79, 161, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(61, 79, 161, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #3d4fa1;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: red;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #db4488;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(61, 79, 161, 0.12);
}

.mat-header-cell {
  color: rgba(61, 79, 161, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(61, 79, 161, 0.87);
}

.mat-calendar-arrow {
  border-top-color: rgba(61, 79, 161, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(61, 79, 161, 0.54);
}

.mat-calendar-table-header {
  color: rgba(61, 79, 161, 0.38);
}

.mat-calendar-table-header-divider::after {
  background: rgba(61, 79, 161, 0.12);
}

.mat-calendar-body-label {
  color: rgba(61, 79, 161, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(61, 79, 161, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(61, 79, 161, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(61, 79, 161, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(61, 79, 161, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(61, 79, 161, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(61, 79, 161, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(61, 79, 161, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(61, 79, 161, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(61, 79, 161, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #3d4fa1;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(61, 79, 161, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(61, 79, 161, 0.3);
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(61, 79, 161, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(219, 68, 136, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(219, 68, 136, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(219, 68, 136, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #db4488;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(219, 68, 136, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(219, 68, 136, 0.3);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 0, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 0, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 0, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: red;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 0, 0, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 0, 0, 0.3);
}

.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #3d4fa1;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #db4488;
}
.mat-datepicker-toggle-active.mat-warn {
  color: red;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(61, 79, 161, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(61, 79, 161, 0.87);
}

.mat-divider {
  border-top-color: rgba(61, 79, 161, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(61, 79, 161, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(61, 79, 161, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(61, 79, 161, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(44, 44, 44, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(61, 79, 161, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(61, 79, 161, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(61, 79, 161, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(61, 79, 161, 0.6);
}

.mat-hint {
  color: rgba(61, 79, 161, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #3d4fa1;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #db4488;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: red;
}

.mat-focused .mat-form-field-required-marker {
  color: #db4488;
}

.mat-form-field-ripple {
  background-color: rgba(61, 79, 161, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #3d4fa1;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #db4488;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: red;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #3d4fa1;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #db4488;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: red;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: red;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: red;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: red;
}

.mat-error {
  color: red;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(61, 79, 161, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(61, 79, 161, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(61, 79, 161, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(61, 79, 161, 0.42) 0%, rgba(61, 79, 161, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(61, 79, 161, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(61, 79, 161, 0.42) 0%, rgba(61, 79, 161, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(61, 79, 161, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(61, 79, 161, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(61, 79, 161, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(61, 79, 161, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #3d4fa1;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #db4488;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: red;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: red;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(61, 79, 161, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(61, 79, 161, 0.06);
}

.mat-icon.mat-primary {
  color: #3d4fa1;
}
.mat-icon.mat-accent {
  color: #db4488;
}
.mat-icon.mat-warn {
  color: red;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(61, 79, 161, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(61, 79, 161, 0.38);
}

.mat-input-element {
  caret-color: #3d4fa1;
}
.mat-input-element::placeholder {
  color: rgba(61, 79, 161, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(61, 79, 161, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(61, 79, 161, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(61, 79, 161, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #db4488;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: red;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: red;
}

.mat-list-base .mat-list-item {
  color: rgba(61, 79, 161, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(61, 79, 161, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(61, 79, 161, 0.54);
}

.mat-list-item-disabled {
  background-color: #e6e6e6;
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(44, 44, 44, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(44, 44, 44, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(61, 79, 161, 0.87);
}
.mat-menu-item[disabled], .mat-menu-item[disabled]::after,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(61, 79, 161, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(61, 79, 161, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(44, 44, 44, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(61, 79, 161, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(61, 79, 161, 0.54);
  border-right: 2px solid rgba(61, 79, 161, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(61, 79, 161, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(61, 79, 161, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #c5cae3;
}

.mat-progress-bar-buffer {
  background-color: #c5cae3;
}

.mat-progress-bar-fill::after {
  background-color: #3d4fa1;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #f4c7db;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #f4c7db;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #db4488;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffb3b3;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffb3b3;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: red;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #3d4fa1;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #db4488;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: red;
}

.mat-radio-outer-circle {
  border-color: rgba(61, 79, 161, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3d4fa1;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #3d4fa1;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #db4488;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #db4488;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: red;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: red;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(61, 79, 161, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(61, 79, 161, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(61, 79, 161, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(61, 79, 161, 0.87);
}

.mat-select-placeholder {
  color: rgba(61, 79, 161, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(61, 79, 161, 0.38);
}

.mat-select-arrow {
  color: rgba(61, 79, 161, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(44, 44, 44, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #3d4fa1;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #db4488;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: red;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: red;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(61, 79, 161, 0.38);
}

.mat-drawer-container {
  background-color: white;
  color: rgba(61, 79, 161, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(61, 79, 161, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(61, 79, 161, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(61, 79, 161, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(61, 79, 161, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(61, 79, 161, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #db4488;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(219, 68, 136, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #db4488;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #3d4fa1;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(61, 79, 161, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #3d4fa1;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: red;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 0, 0, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: red;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(61, 79, 161, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(61, 79, 161, 0.26);
}

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #3d4fa1;
}
.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-primary .mat-slider-focus-ring {
  background-color: rgba(61, 79, 161, 0.2);
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #db4488;
}
.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-accent .mat-slider-focus-ring {
  background-color: rgba(219, 68, 136, 0.2);
}

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: red;
}
.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 0, 0, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(61, 79, 161, 0.38);
}

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(61, 79, 161, 0.26);
}
.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(61, 79, 161, 0.26);
}

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(61, 79, 161, 0.87);
}
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(61, 79, 161, 0.26);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(61, 79, 161, 0.26);
  background-color: transparent;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(61, 79, 161, 0.38);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(61, 79, 161, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(44, 44, 44, 0.04);
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(61, 79, 161, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(61, 79, 161, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #3d4fa1;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #db4488;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: red;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: red;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(61, 79, 161, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: red;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(61, 79, 161, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(61, 79, 161, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #96a0cc;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(61, 79, 161, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(61, 79, 161, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(61, 79, 161, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(61, 79, 161, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(61, 79, 161, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(61, 79, 161, 0.38);
}

.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 227, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #3d4fa1;
}
.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(244, 199, 219, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #db4488;
}
.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: red;
}
.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 227, 0.3);
}
.mat-tab-group.mat-background-primary .mat-tab-header, .mat-tab-group.mat-background-primary .mat-tab-links, .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #3d4fa1;
}
.mat-tab-group.mat-background-primary .mat-tab-label, .mat-tab-group.mat-background-primary .mat-tab-link, .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(244, 199, 219, 0.3);
}
.mat-tab-group.mat-background-accent .mat-tab-header, .mat-tab-group.mat-background-accent .mat-tab-links, .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #db4488;
}
.mat-tab-group.mat-background-accent .mat-tab-label, .mat-tab-group.mat-background-accent .mat-tab-link, .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.mat-tab-group.mat-background-warn .mat-tab-header, .mat-tab-group.mat-background-warn .mat-tab-links, .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: red;
}
.mat-tab-group.mat-background-warn .mat-tab-label, .mat-tab-group.mat-background-warn .mat-tab-link, .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-toolbar {
  background: #f2f2f2;
  color: rgba(61, 79, 161, 0.87);
}
.mat-toolbar.mat-primary {
  background: #3d4fa1;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #db4488;
  color: white;
}
.mat-toolbar.mat-warn {
  background: red;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(95, 95, 95, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(61, 79, 161, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #db4488;
}

.mtx-alert-ref {
  background-color: white;
}
.mtx-alert-info .mtx-alert-ref {
  background-color: #2196f3;
  border-color: #2196f3;
  color: white;
}
.mtx-alert-success .mtx-alert-ref {
  background-color: #4caf50;
  border-color: #4caf50;
  color: white;
}
.mtx-alert-warning .mtx-alert-ref {
  background-color: #ff9800;
  border-color: #ff9800;
  color: white;
}
.mtx-alert-danger .mtx-alert-ref {
  background-color: #f44336;
  border-color: #f44336;
  color: white;
}

.mtx-grid {
  border: 1px solid rgba(61, 79, 161, 0.2);
}
.mtx-grid .mtx-grid-toolbar {
  border-bottom: 1px solid rgba(61, 79, 161, 0.2);
}
.mtx-grid .mtx-grid-sidebar {
  border-left: 1px solid rgba(61, 79, 161, 0.2);
}
[dir=rtl] .mtx-grid .mtx-grid-sidebar {
  border-left-width: 0;
  border-right: 1px solid rgba(61, 79, 161, 0.2);
}
.mtx-grid .mat-table-sticky-left {
  border-right: 1px solid rgba(61, 79, 161, 0.12);
}
[dir=rtl] .mtx-grid .mat-table-sticky-left {
  border-right-width: 0;
  border-left: 1px solid rgba(61, 79, 161, 0.12);
}
.mtx-grid .mat-table-sticky-right {
  border-left: 1px solid rgba(61, 79, 161, 0.12);
}
[dir=rtl] .mtx-grid .mat-table-sticky-right {
  border-left-width: 0;
  border-right: 1px solid rgba(61, 79, 161, 0.12);
}
.mtx-grid .mat-table.mat-table-striped .mat-row-odd {
  background-color: #f5f5f5;
}
.mtx-grid .mat-table.mat-table-hover .mat-row:hover {
  background-color: #e5e5e5;
}
.mtx-grid .mat-row.selected, .mtx-grid .mat-row.mat-row-odd.selected {
  background-color: #e5e5e5;
}
.mtx-grid .mat-cell.selected {
  box-shadow: inset 0 0 0 1px #db4488;
}
.mtx-grid .mat-paginator {
  border-top: 1px solid rgba(61, 79, 161, 0.12);
}
.mtx-grid .mat-footer-row .mat-footer-cell {
  border-top: 1px solid rgba(61, 79, 161, 0.12);
  border-bottom-width: 0;
  background-color: #f2f2f2;
}

.mtx-grid-column-menu-item {
  color: rgba(61, 79, 161, 0.87);
}

.mtx-grid-column-menu-header,
.mtx-grid-column-menu-footer {
  color: rgba(61, 79, 161, 0.87);
  background-color: white;
}

.mtx-grid-column-menu-header {
  border-bottom: 1px solid rgba(61, 79, 161, 0.12);
}

.mtx-grid-column-menu-footer {
  border-top: 1px solid rgba(61, 79, 161, 0.12);
}

.mat-column-resize-table.cdk-column-resize-with-resized-column {
  table-layout: fixed;
}

.mat-column-resize-flex .mat-header-cell,
.mat-column-resize-flex .mat-cell {
  box-sizing: border-box;
  min-width: 32px;
}

.mat-header-cell {
  position: relative;
}

.mat-resizable {
  box-sizing: border-box;
}

.mat-header-cell:not(.mat-resizable)::after,
.mat-resizable-handle {
  background: transparent;
  bottom: 0;
  position: absolute;
  top: 0;
  transition: background 300ms cubic-bezier(0.55, 0, 0.55, 0.2);
  width: 1px;
}

.mat-header-cell:not(.mat-resizable)::after {
  content: "";
}

.mat-header-cell:not(.mat-resizable)::after,
.mat-resizable-handle {
  right: 0;
}

[dir=rtl] .mat-header-cell:not(.mat-resizable)::after,
[dir=rtl] .mat-resizable-handle {
  left: 0;
  right: auto;
}

.mat-header-row.cdk-column-resize-hover-or-active .mat-header-cell:not(.mat-resizable)::after {
  background: rgba(61, 79, 161, 0.12);
}
.mat-header-row.cdk-column-resize-hover-or-active .mat-resizable-handle {
  background: #3d4fa1;
}

.mat-resizable.cdk-resizable-overlay-thumb-active > .mat-resizable-handle {
  opacity: 0;
  transition: none;
}

.mat-resizable-handle:focus,
.mat-header-row.cdk-column-resize-hover-or-active .mat-resizable-handle:focus {
  outline: none;
}

.mat-column-resize-overlay-thumb {
  background: transparent;
  cursor: col-resize;
  height: 100%;
  transition: background 300ms cubic-bezier(0.55, 0, 0.55, 0.2);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}
.mat-column-resize-overlay-thumb:active {
  background: linear-gradient(90deg, transparent, transparent 7px, , 1px, transparent 8px, transparent);
  will-change: transform;
}

.mat-table-sticky {
  position: sticky;
}

.mtx-loader-backdrop {
  background-color: rgba(255, 255, 255, 0.75);
}

.mtx-popover-panel {
  color: rgba(61, 79, 161, 0.87);
  background-color: white;
}

.mtx-popover-below .mtx-popover-direction-arrow::before {
  border-bottom-color: rgba(61, 79, 161, 0.2);
}
.mtx-popover-below .mtx-popover-direction-arrow::after {
  border-bottom-color: white;
}

.mtx-popover-above .mtx-popover-direction-arrow::before {
  border-top-color: rgba(61, 79, 161, 0.2);
}
.mtx-popover-above .mtx-popover-direction-arrow::after {
  border-top-color: white;
}

.mtx-progress {
  background-color: rgba(44, 44, 44, 0.04);
}

.mtx-progress-fill-info {
  background-color: #2196f3;
  color: white;
}

.mtx-progress-fill-success {
  background-color: #4caf50;
  color: white;
}

.mtx-progress-fill-warning {
  background-color: #ff9800;
  color: white;
}

.mtx-progress-fill-danger {
  background-color: #f44336;
  color: white;
}

.ng-select .ng-select-container {
  color: rgba(61, 79, 161, 0.87);
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: rgba(61, 79, 161, 0.38);
}
.ng-select .ng-select-container .ng-value-container .ng-input > input {
  color: rgba(61, 79, 161, 0.87);
}
.ng-select.ng-select-opened .ng-arrow-wrapper .ng-arrow {
  border-color: transparent transparent rgba(61, 79, 161, 0.54);
}
.mtx-select-invalid .ng-select.ng-select-opened .ng-arrow-wrapper .ng-arrow {
  border-color: transparent transparent red;
}
.ng-select.ng-select-disabled .ng-value {
  color: rgba(61, 79, 161, 0.38);
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  color: rgba(61, 79, 161, 0.87);
  background-color: #e6e6e6;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
  opacity: 0.4;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: rgba(61, 79, 161, 0.12);
}
.ng-select .ng-clear-wrapper {
  color: rgba(61, 79, 161, 0.54);
}
.ng-select .ng-clear-wrapper:hover .ng-clear {
  color: red;
}
.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: rgba(61, 79, 161, 0.54) transparent transparent;
}
.mtx-select-invalid .ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: red transparent transparent;
}

.ng-dropdown-panel {
  background-color: white;
}
.ng-dropdown-panel.multiple .ng-option.selected {
  background: rgba(61, 79, 161, 0.12);
}
.ng-dropdown-panel.multiple .ng-option.marked {
  background: rgba(44, 44, 44, 0.04);
}
.ng-dropdown-panel .ng-dropdown-header {
  border-bottom: 1px solid rgba(61, 79, 161, 0.12);
}
.ng-dropdown-panel .ng-dropdown-footer {
  border-top: 1px solid rgba(61, 79, 161, 0.12);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  color: rgba(61, 79, 161, 0.54);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background: rgba(44, 44, 44, 0.04);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected {
  background: rgba(61, 79, 161, 0.12);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: rgba(61, 79, 161, 0.87);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background: rgba(44, 44, 44, 0.04);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background: rgba(61, 79, 161, 0.12);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: rgba(61, 79, 161, 0.38);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  color: rgba(61, 79, 161, 0.38);
}

.mtx-split > .mtx-split-gutter {
  background-color: rgba(61, 79, 161, 0.24);
}
.mtx-split > .mtx-split-gutter:hover {
  background-color: #db4488;
}

.mtx-tooltip {
  background: rgba(95, 95, 95, 0.9);
}

.mtx-tooltip {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
}

.mtx-tooltip-handset {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
}

.theme-darak .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-darak .mat-option {
  color: white;
}
.theme-darak .mat-option:hover:not(.mat-option-disabled), .theme-darak .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.theme-darak .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.theme-darak .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.theme-darak .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #3d4fa1;
}
.theme-darak .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #db4488;
}
.theme-darak .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: red;
}
.theme-darak .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-darak .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.theme-darak .mat-pseudo-checkbox::after {
  color: #2c2c2c;
}
.theme-darak .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.theme-darak .mat-primary .mat-pseudo-checkbox-checked,
.theme-darak .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #3d4fa1;
}
.theme-darak .mat-pseudo-checkbox-checked,
.theme-darak .mat-pseudo-checkbox-indeterminate,
.theme-darak .mat-accent .mat-pseudo-checkbox-checked,
.theme-darak .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #db4488;
}
.theme-darak .mat-warn .mat-pseudo-checkbox-checked,
.theme-darak .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: red;
}
.theme-darak .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-darak .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.theme-darak .mat-app-background, .theme-darak.mat-app-background {
  background-color: #2c2c2c;
  color: white;
}
.theme-darak .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-darak .mat-autocomplete-panel {
  background: #393939;
  color: white;
}
.theme-darak .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #393939;
}
.theme-darak .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.theme-darak .mat-badge-content {
  color: white;
  background: #3d4fa1;
}
.cdk-high-contrast-active .theme-darak .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-darak .mat-badge-accent .mat-badge-content {
  background: #db4488;
  color: white;
}
.theme-darak .mat-badge-warn .mat-badge-content {
  color: white;
  background: red;
}
.theme-darak .mat-badge {
  position: relative;
}
.theme-darak .mat-badge-hidden .mat-badge-content {
  display: none;
}
.theme-darak .mat-badge-disabled .mat-badge-content {
  background: #6b6b6b;
  color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.theme-darak .ng-animate-disabled .mat-badge-content,
.theme-darak .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.theme-darak .mat-badge-content.mat-badge-active {
  transform: none;
}
.theme-darak .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.theme-darak .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.theme-darak .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.theme-darak .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .theme-darak .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.theme-darak .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .theme-darak .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.theme-darak .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .theme-darak .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.theme-darak .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .theme-darak .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.theme-darak .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.theme-darak .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.theme-darak .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.theme-darak .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .theme-darak .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.theme-darak .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .theme-darak .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.theme-darak .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .theme-darak .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.theme-darak .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .theme-darak .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.theme-darak .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.theme-darak .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.theme-darak .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.theme-darak .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .theme-darak .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.theme-darak .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .theme-darak .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.theme-darak .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .theme-darak .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.theme-darak .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .theme-darak .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.theme-darak .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #393939;
  color: white;
}
.theme-darak .mat-button, .theme-darak .mat-icon-button, .theme-darak .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.theme-darak .mat-button.mat-primary, .theme-darak .mat-icon-button.mat-primary, .theme-darak .mat-stroked-button.mat-primary {
  color: #3d4fa1;
}
.theme-darak .mat-button.mat-accent, .theme-darak .mat-icon-button.mat-accent, .theme-darak .mat-stroked-button.mat-accent {
  color: #db4488;
}
.theme-darak .mat-button.mat-warn, .theme-darak .mat-icon-button.mat-warn, .theme-darak .mat-stroked-button.mat-warn {
  color: red;
}
.theme-darak .mat-button.mat-primary.mat-button-disabled, .theme-darak .mat-button.mat-accent.mat-button-disabled, .theme-darak .mat-button.mat-warn.mat-button-disabled, .theme-darak .mat-button.mat-button-disabled.mat-button-disabled, .theme-darak .mat-icon-button.mat-primary.mat-button-disabled, .theme-darak .mat-icon-button.mat-accent.mat-button-disabled, .theme-darak .mat-icon-button.mat-warn.mat-button-disabled, .theme-darak .mat-icon-button.mat-button-disabled.mat-button-disabled, .theme-darak .mat-stroked-button.mat-primary.mat-button-disabled, .theme-darak .mat-stroked-button.mat-accent.mat-button-disabled, .theme-darak .mat-stroked-button.mat-warn.mat-button-disabled, .theme-darak .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.theme-darak .mat-button.mat-primary .mat-button-focus-overlay, .theme-darak .mat-icon-button.mat-primary .mat-button-focus-overlay, .theme-darak .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #3d4fa1;
}
.theme-darak .mat-button.mat-accent .mat-button-focus-overlay, .theme-darak .mat-icon-button.mat-accent .mat-button-focus-overlay, .theme-darak .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #db4488;
}
.theme-darak .mat-button.mat-warn .mat-button-focus-overlay, .theme-darak .mat-icon-button.mat-warn .mat-button-focus-overlay, .theme-darak .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: red;
}
.theme-darak .mat-button.mat-button-disabled .mat-button-focus-overlay, .theme-darak .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .theme-darak .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.theme-darak .mat-button .mat-ripple-element, .theme-darak .mat-icon-button .mat-ripple-element, .theme-darak .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.theme-darak .mat-button-focus-overlay {
  background: #ffffff;
}
.theme-darak .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-darak .mat-flat-button, .theme-darak .mat-raised-button, .theme-darak .mat-fab, .theme-darak .mat-mini-fab {
  color: white;
  background-color: #393939;
}
.theme-darak .mat-flat-button.mat-primary, .theme-darak .mat-raised-button.mat-primary, .theme-darak .mat-fab.mat-primary, .theme-darak .mat-mini-fab.mat-primary {
  color: white;
}
.theme-darak .mat-flat-button.mat-accent, .theme-darak .mat-raised-button.mat-accent, .theme-darak .mat-fab.mat-accent, .theme-darak .mat-mini-fab.mat-accent {
  color: white;
}
.theme-darak .mat-flat-button.mat-warn, .theme-darak .mat-raised-button.mat-warn, .theme-darak .mat-fab.mat-warn, .theme-darak .mat-mini-fab.mat-warn {
  color: white;
}
.theme-darak .mat-flat-button.mat-primary.mat-button-disabled, .theme-darak .mat-flat-button.mat-accent.mat-button-disabled, .theme-darak .mat-flat-button.mat-warn.mat-button-disabled, .theme-darak .mat-flat-button.mat-button-disabled.mat-button-disabled, .theme-darak .mat-raised-button.mat-primary.mat-button-disabled, .theme-darak .mat-raised-button.mat-accent.mat-button-disabled, .theme-darak .mat-raised-button.mat-warn.mat-button-disabled, .theme-darak .mat-raised-button.mat-button-disabled.mat-button-disabled, .theme-darak .mat-fab.mat-primary.mat-button-disabled, .theme-darak .mat-fab.mat-accent.mat-button-disabled, .theme-darak .mat-fab.mat-warn.mat-button-disabled, .theme-darak .mat-fab.mat-button-disabled.mat-button-disabled, .theme-darak .mat-mini-fab.mat-primary.mat-button-disabled, .theme-darak .mat-mini-fab.mat-accent.mat-button-disabled, .theme-darak .mat-mini-fab.mat-warn.mat-button-disabled, .theme-darak .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.theme-darak .mat-flat-button.mat-primary, .theme-darak .mat-raised-button.mat-primary, .theme-darak .mat-fab.mat-primary, .theme-darak .mat-mini-fab.mat-primary {
  background-color: #3d4fa1;
}
.theme-darak .mat-flat-button.mat-accent, .theme-darak .mat-raised-button.mat-accent, .theme-darak .mat-fab.mat-accent, .theme-darak .mat-mini-fab.mat-accent {
  background-color: #db4488;
}
.theme-darak .mat-flat-button.mat-warn, .theme-darak .mat-raised-button.mat-warn, .theme-darak .mat-fab.mat-warn, .theme-darak .mat-mini-fab.mat-warn {
  background-color: red;
}
.theme-darak .mat-flat-button.mat-primary.mat-button-disabled, .theme-darak .mat-flat-button.mat-accent.mat-button-disabled, .theme-darak .mat-flat-button.mat-warn.mat-button-disabled, .theme-darak .mat-flat-button.mat-button-disabled.mat-button-disabled, .theme-darak .mat-raised-button.mat-primary.mat-button-disabled, .theme-darak .mat-raised-button.mat-accent.mat-button-disabled, .theme-darak .mat-raised-button.mat-warn.mat-button-disabled, .theme-darak .mat-raised-button.mat-button-disabled.mat-button-disabled, .theme-darak .mat-fab.mat-primary.mat-button-disabled, .theme-darak .mat-fab.mat-accent.mat-button-disabled, .theme-darak .mat-fab.mat-warn.mat-button-disabled, .theme-darak .mat-fab.mat-button-disabled.mat-button-disabled, .theme-darak .mat-mini-fab.mat-primary.mat-button-disabled, .theme-darak .mat-mini-fab.mat-accent.mat-button-disabled, .theme-darak .mat-mini-fab.mat-warn.mat-button-disabled, .theme-darak .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-darak .mat-flat-button.mat-primary .mat-ripple-element, .theme-darak .mat-raised-button.mat-primary .mat-ripple-element, .theme-darak .mat-fab.mat-primary .mat-ripple-element, .theme-darak .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-darak .mat-flat-button.mat-accent .mat-ripple-element, .theme-darak .mat-raised-button.mat-accent .mat-ripple-element, .theme-darak .mat-fab.mat-accent .mat-ripple-element, .theme-darak .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-darak .mat-flat-button.mat-warn .mat-ripple-element, .theme-darak .mat-raised-button.mat-warn .mat-ripple-element, .theme-darak .mat-fab.mat-warn .mat-ripple-element, .theme-darak .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-darak .mat-stroked-button:not([class*=mat-elevation-z]), .theme-darak .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-fab:not([class*=mat-elevation-z]), .theme-darak .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .theme-darak .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .theme-darak .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-button-toggle-standalone,
.theme-darak .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-darak .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.theme-darak .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-darak .mat-button-toggle-appearance-standard {
  color: white;
  background: #393939;
}
.theme-darak .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.theme-darak .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-darak [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-darak .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-darak .mat-button-toggle-checked {
  background-color: #5f5f5f;
  color: rgba(255, 255, 255, 0.7);
}
.theme-darak .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.theme-darak .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: #464646;
}
.theme-darak .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #393939;
}
.theme-darak .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #797979;
}
.theme-darak .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-darak .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-darak .mat-card {
  background: #393939;
  color: white;
}
.theme-darak .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.theme-darak .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-darak .mat-checkbox-checkmark {
  fill: #2c2c2c;
}
.theme-darak .mat-checkbox-checkmark-path {
  stroke: #2c2c2c !important;
}
.theme-darak .mat-checkbox-mixedmark {
  background-color: #2c2c2c;
}
.theme-darak .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .theme-darak .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #3d4fa1;
}
.theme-darak .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .theme-darak .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #db4488;
}
.theme-darak .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .theme-darak .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: red;
}
.theme-darak .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .theme-darak .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.theme-darak .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.theme-darak .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-darak .mat-checkbox .mat-ripple-element {
  background-color: #ffffff;
}
.theme-darak .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.theme-darak .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #3d4fa1;
}
.theme-darak .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.theme-darak .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #db4488;
}
.theme-darak .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.theme-darak .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: red;
}
.theme-darak .mat-chip.mat-standard-chip {
  background-color: #5f5f5f;
  color: white;
}
.theme-darak .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-darak .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.theme-darak .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.theme-darak .mat-chip.mat-standard-chip::after {
  background: #ffffff;
}
.theme-darak .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #3d4fa1;
  color: white;
}
.theme-darak .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-darak .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-darak .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: red;
  color: white;
}
.theme-darak .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-darak .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-darak .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #db4488;
  color: white;
}
.theme-darak .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-darak .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-darak .mat-table {
  background: #393939;
}
.theme-darak .mat-table thead, .theme-darak .mat-table tbody, .theme-darak .mat-table tfoot,
.theme-darak mat-header-row, .theme-darak mat-row, .theme-darak mat-footer-row,
.theme-darak [mat-header-row], .theme-darak [mat-row], .theme-darak [mat-footer-row],
.theme-darak .mat-table-sticky {
  background: inherit;
}
.theme-darak mat-row, .theme-darak mat-header-row, .theme-darak mat-footer-row,
.theme-darak th.mat-header-cell, .theme-darak td.mat-cell, .theme-darak td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.theme-darak .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.theme-darak .mat-cell, .theme-darak .mat-footer-cell {
  color: white;
}
.theme-darak .mat-calendar-arrow {
  border-top-color: white;
}
.theme-darak .mat-datepicker-toggle,
.theme-darak .mat-datepicker-content .mat-calendar-next-button,
.theme-darak .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.theme-darak .mat-calendar-table-header {
  color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.theme-darak .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-darak .mat-calendar-body-cell-content,
.theme-darak .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.theme-darak .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.theme-darak .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-darak .mat-calendar-body-in-range::before {
  background: rgba(61, 79, 161, 0.2);
}
.theme-darak .mat-calendar-body-comparison-identical,
.theme-darak .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-darak .mat-calendar-body-comparison-bridge-start::before,
.theme-darak [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(61, 79, 161, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-darak .mat-calendar-body-comparison-bridge-end::before,
.theme-darak [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(61, 79, 161, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-darak .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-darak .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-darak .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-darak .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-darak .mat-calendar-body-selected {
  background-color: #3d4fa1;
  color: white;
}
.theme-darak .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(61, 79, 161, 0.4);
}
.theme-darak .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-darak .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-darak .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-darak .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(61, 79, 161, 0.3);
}
.theme-darak .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #393939;
  color: white;
}
.theme-darak .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(219, 68, 136, 0.2);
}
.theme-darak .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-darak .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-darak .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-darak .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(219, 68, 136, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-darak .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-darak .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(219, 68, 136, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-darak .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-darak .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-darak .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-darak .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-darak .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #db4488;
  color: white;
}
.theme-darak .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(219, 68, 136, 0.4);
}
.theme-darak .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-darak .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-darak .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-darak .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(219, 68, 136, 0.3);
}
.theme-darak .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 0, 0, 0.2);
}
.theme-darak .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-darak .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-darak .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-darak .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 0, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-darak .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-darak .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 0, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-darak .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-darak .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-darak .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-darak .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-darak .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: red;
  color: white;
}
.theme-darak .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 0, 0, 0.4);
}
.theme-darak .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-darak .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-darak .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-darak .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 0, 0, 0.3);
}
.theme-darak .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-datepicker-toggle-active {
  color: #3d4fa1;
}
.theme-darak .mat-datepicker-toggle-active.mat-accent {
  color: #db4488;
}
.theme-darak .mat-datepicker-toggle-active.mat-warn {
  color: red;
}
.theme-darak .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #393939;
  color: white;
}
.theme-darak .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-darak .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.theme-darak .mat-expansion-panel {
  background: #393939;
  color: white;
}
.theme-darak .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-darak .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-darak .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-darak .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .theme-darak .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #393939;
  }
}
.theme-darak .mat-expansion-panel-header-title {
  color: white;
}
.theme-darak .mat-expansion-panel-header-description,
.theme-darak .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.theme-darak .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.theme-darak .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-darak .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-darak .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-darak .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.theme-darak .mat-form-field.mat-focused .mat-form-field-label {
  color: #3d4fa1;
}
.theme-darak .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #db4488;
}
.theme-darak .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: red;
}
.theme-darak .mat-focused .mat-form-field-required-marker {
  color: #db4488;
}
.theme-darak .mat-form-field-ripple {
  background-color: white;
}
.theme-darak .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #3d4fa1;
}
.theme-darak .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #db4488;
}
.theme-darak .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: red;
}
.theme-darak .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #3d4fa1;
}
.theme-darak .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #db4488;
}
.theme-darak .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: red;
}
.theme-darak .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: red;
}
.theme-darak .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.theme-darak .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: red;
}
.theme-darak .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.theme-darak .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: red;
}
.theme-darak .mat-error {
  color: red;
}
.theme-darak .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-darak .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.theme-darak .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.theme-darak .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-darak .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.theme-darak .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-darak .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-darak .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.theme-darak .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.theme-darak .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.theme-darak .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.theme-darak .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #3d4fa1;
}
.theme-darak .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #db4488;
}
.theme-darak .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: red;
}
.theme-darak .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: red;
}
.theme-darak .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.theme-darak .mat-icon.mat-primary {
  color: #3d4fa1;
}
.theme-darak .mat-icon.mat-accent {
  color: #db4488;
}
.theme-darak .mat-icon.mat-warn {
  color: red;
}
.theme-darak .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.theme-darak .mat-input-element:disabled,
.theme-darak .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-input-element {
  caret-color: #3d4fa1;
}
.theme-darak .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-input-element option {
  color: rgba(61, 79, 161, 0.87);
}
.theme-darak .mat-input-element option:disabled {
  color: rgba(61, 79, 161, 0.38);
}
.theme-darak .mat-form-field.mat-accent .mat-input-element {
  caret-color: #db4488;
}
.theme-darak .mat-form-field.mat-warn .mat-input-element,
.theme-darak .mat-form-field-invalid .mat-input-element {
  caret-color: red;
}
.theme-darak .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: red;
}
.theme-darak .mat-list-base .mat-list-item {
  color: white;
}
.theme-darak .mat-list-base .mat-list-option {
  color: white;
}
.theme-darak .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.theme-darak .mat-list-item-disabled {
  background-color: #464646;
}
.theme-darak .mat-list-option:hover, .theme-darak .mat-list-option:focus,
.theme-darak .mat-nav-list .mat-list-item:hover,
.theme-darak .mat-nav-list .mat-list-item:focus,
.theme-darak .mat-action-list .mat-list-item:hover,
.theme-darak .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.theme-darak .mat-list-single-selected-option, .theme-darak .mat-list-single-selected-option:hover, .theme-darak .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.theme-darak .mat-menu-panel {
  background: #393939;
}
.theme-darak .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-menu-item {
  background: transparent;
  color: white;
}
.theme-darak .mat-menu-item[disabled], .theme-darak .mat-menu-item[disabled]::after,
.theme-darak .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-menu-item .mat-icon-no-color,
.theme-darak .mat-menu-item-submenu-trigger::after {
  color: white;
}
.theme-darak .mat-menu-item:hover:not([disabled]),
.theme-darak .mat-menu-item.cdk-program-focused:not([disabled]),
.theme-darak .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-darak .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.theme-darak .mat-paginator {
  background: #393939;
}
.theme-darak .mat-paginator,
.theme-darak .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.theme-darak .mat-paginator-decrement,
.theme-darak .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.theme-darak .mat-paginator-first,
.theme-darak .mat-paginator-last {
  border-top: 2px solid white;
}
.theme-darak .mat-icon-button[disabled] .mat-paginator-decrement,
.theme-darak .mat-icon-button[disabled] .mat-paginator-increment,
.theme-darak .mat-icon-button[disabled] .mat-paginator-first,
.theme-darak .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-progress-bar-background {
  fill: #c5cae3;
}
.theme-darak .mat-progress-bar-buffer {
  background-color: #c5cae3;
}
.theme-darak .mat-progress-bar-fill::after {
  background-color: #3d4fa1;
}
.theme-darak .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #f4c7db;
}
.theme-darak .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #f4c7db;
}
.theme-darak .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #db4488;
}
.theme-darak .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffb3b3;
}
.theme-darak .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffb3b3;
}
.theme-darak .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: red;
}
.theme-darak .mat-progress-spinner circle, .theme-darak .mat-spinner circle {
  stroke: #3d4fa1;
}
.theme-darak .mat-progress-spinner.mat-accent circle, .theme-darak .mat-spinner.mat-accent circle {
  stroke: #db4488;
}
.theme-darak .mat-progress-spinner.mat-warn circle, .theme-darak .mat-spinner.mat-warn circle {
  stroke: red;
}
.theme-darak .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-darak .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3d4fa1;
}
.theme-darak .mat-radio-button.mat-primary .mat-radio-inner-circle,
.theme-darak .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-darak .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .theme-darak .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #3d4fa1;
}
.theme-darak .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #db4488;
}
.theme-darak .mat-radio-button.mat-accent .mat-radio-inner-circle,
.theme-darak .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-darak .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .theme-darak .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #db4488;
}
.theme-darak .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: red;
}
.theme-darak .mat-radio-button.mat-warn .mat-radio-inner-circle,
.theme-darak .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-darak .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .theme-darak .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: red;
}
.theme-darak .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.theme-darak .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.theme-darak .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-radio-button .mat-ripple-element {
  background-color: #ffffff;
}
.theme-darak .mat-select-value {
  color: white;
}
.theme-darak .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.theme-darak .mat-select-panel {
  background: #393939;
}
.theme-darak .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.theme-darak .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #3d4fa1;
}
.theme-darak .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #db4488;
}
.theme-darak .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: red;
}
.theme-darak .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: red;
}
.theme-darak .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-drawer-container {
  background-color: #2c2c2c;
  color: white;
}
.theme-darak .mat-drawer {
  background-color: #393939;
  color: white;
}
.theme-darak .mat-drawer.mat-drawer-push {
  background-color: #393939;
}
.theme-darak .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-darak .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.theme-darak [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.theme-darak [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-darak .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(198, 198, 198, 0.6);
}
.theme-darak .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #db4488;
}
.theme-darak .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(219, 68, 136, 0.54);
}
.theme-darak .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #db4488;
}
.theme-darak .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #3d4fa1;
}
.theme-darak .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(61, 79, 161, 0.54);
}
.theme-darak .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #3d4fa1;
}
.theme-darak .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: red;
}
.theme-darak .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 0, 0, 0.54);
}
.theme-darak .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: red;
}
.theme-darak .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.theme-darak .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.theme-darak .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-darak .mat-primary .mat-slider-track-fill,
.theme-darak .mat-primary .mat-slider-thumb,
.theme-darak .mat-primary .mat-slider-thumb-label {
  background-color: #3d4fa1;
}
.theme-darak .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.theme-darak .mat-primary .mat-slider-focus-ring {
  background-color: rgba(61, 79, 161, 0.2);
}
.theme-darak .mat-accent .mat-slider-track-fill,
.theme-darak .mat-accent .mat-slider-thumb,
.theme-darak .mat-accent .mat-slider-thumb-label {
  background-color: #db4488;
}
.theme-darak .mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.theme-darak .mat-accent .mat-slider-focus-ring {
  background-color: rgba(219, 68, 136, 0.2);
}
.theme-darak .mat-warn .mat-slider-track-fill,
.theme-darak .mat-warn .mat-slider-thumb,
.theme-darak .mat-warn .mat-slider-thumb-label {
  background-color: red;
}
.theme-darak .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.theme-darak .mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 0, 0, 0.2);
}
.theme-darak .mat-slider:hover .mat-slider-track-background,
.theme-darak .cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-darak .mat-slider-disabled .mat-slider-track-background,
.theme-darak .mat-slider-disabled .mat-slider-track-fill,
.theme-darak .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-darak .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-darak .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-darak .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.theme-darak .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.theme-darak .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.theme-darak .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-darak .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.theme-darak .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .theme-darak .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-darak .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .theme-darak .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-darak .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-darak .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.theme-darak .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.theme-darak .mat-step-header.cdk-keyboard-focused, .theme-darak .mat-step-header.cdk-program-focused, .theme-darak .mat-step-header:hover {
  background-color: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .theme-darak .mat-step-header:hover {
    background: none;
  }
}
.theme-darak .mat-step-header .mat-step-label,
.theme-darak .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.theme-darak .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.theme-darak .mat-step-header .mat-step-icon-selected,
.theme-darak .mat-step-header .mat-step-icon-state-done,
.theme-darak .mat-step-header .mat-step-icon-state-edit {
  background-color: #3d4fa1;
  color: white;
}
.theme-darak .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.theme-darak .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-darak .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-darak .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #db4488;
  color: white;
}
.theme-darak .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.theme-darak .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-darak .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-darak .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: red;
  color: white;
}
.theme-darak .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: red;
}
.theme-darak .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.theme-darak .mat-step-header .mat-step-label.mat-step-label-error {
  color: red;
}
.theme-darak .mat-stepper-horizontal, .theme-darak .mat-stepper-vertical {
  background-color: #393939;
}
.theme-darak .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.theme-darak .mat-horizontal-stepper-header::before,
.theme-darak .mat-horizontal-stepper-header::after,
.theme-darak .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-darak .mat-sort-header-arrow {
  color: #c4c4c4;
}
.theme-darak .mat-tab-nav-bar,
.theme-darak .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.theme-darak .mat-tab-group-inverted-header .mat-tab-nav-bar,
.theme-darak .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.theme-darak .mat-tab-label, .theme-darak .mat-tab-link {
  color: white;
}
.theme-darak .mat-tab-label.mat-tab-disabled, .theme-darak .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-darak .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-darak .mat-tab-group[class*=mat-background-] .mat-tab-header,
.theme-darak .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.theme-darak .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-darak .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-darak .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-darak .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 227, 0.3);
}
.theme-darak .mat-tab-group.mat-primary .mat-ink-bar, .theme-darak .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #3d4fa1;
}
.theme-darak .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .theme-darak .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.theme-darak .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-darak .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-darak .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-darak .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(244, 199, 219, 0.3);
}
.theme-darak .mat-tab-group.mat-accent .mat-ink-bar, .theme-darak .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #db4488;
}
.theme-darak .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .theme-darak .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}
.theme-darak .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-darak .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-darak .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-darak .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.theme-darak .mat-tab-group.mat-warn .mat-ink-bar, .theme-darak .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: red;
}
.theme-darak .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .theme-darak .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.theme-darak .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-darak .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-darak .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-darak .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 227, 0.3);
}
.theme-darak .mat-tab-group.mat-background-primary .mat-tab-header, .theme-darak .mat-tab-group.mat-background-primary .mat-tab-links, .theme-darak .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .theme-darak .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .theme-darak .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .theme-darak .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #3d4fa1;
}
.theme-darak .mat-tab-group.mat-background-primary .mat-tab-label, .theme-darak .mat-tab-group.mat-background-primary .mat-tab-link, .theme-darak .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .theme-darak .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.theme-darak .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .theme-darak .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .theme-darak .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .theme-darak .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-darak .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .theme-darak .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-darak .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-darak .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.theme-darak .mat-tab-group.mat-background-primary .mat-ripple-element, .theme-darak .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-darak .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-darak .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-darak .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-darak .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(244, 199, 219, 0.3);
}
.theme-darak .mat-tab-group.mat-background-accent .mat-tab-header, .theme-darak .mat-tab-group.mat-background-accent .mat-tab-links, .theme-darak .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .theme-darak .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .theme-darak .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .theme-darak .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #db4488;
}
.theme-darak .mat-tab-group.mat-background-accent .mat-tab-label, .theme-darak .mat-tab-group.mat-background-accent .mat-tab-link, .theme-darak .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .theme-darak .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}
.theme-darak .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .theme-darak .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .theme-darak .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .theme-darak .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-darak .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .theme-darak .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-darak .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-darak .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.theme-darak .mat-tab-group.mat-background-accent .mat-ripple-element, .theme-darak .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-darak .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-darak .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-darak .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-darak .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-darak .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.theme-darak .mat-tab-group.mat-background-warn .mat-tab-header, .theme-darak .mat-tab-group.mat-background-warn .mat-tab-links, .theme-darak .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .theme-darak .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .theme-darak .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .theme-darak .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: red;
}
.theme-darak .mat-tab-group.mat-background-warn .mat-tab-label, .theme-darak .mat-tab-group.mat-background-warn .mat-tab-link, .theme-darak .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .theme-darak .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.theme-darak .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .theme-darak .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .theme-darak .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .theme-darak .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-darak .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .theme-darak .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-darak .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-darak .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.theme-darak .mat-tab-group.mat-background-warn .mat-ripple-element, .theme-darak .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-darak .mat-toolbar {
  background: #393939;
  color: white;
}
.theme-darak .mat-toolbar.mat-primary {
  background: #3d4fa1;
  color: white;
}
.theme-darak .mat-toolbar.mat-accent {
  background: #db4488;
  color: white;
}
.theme-darak .mat-toolbar.mat-warn {
  background: red;
  color: white;
}
.theme-darak .mat-toolbar .mat-form-field-underline,
.theme-darak .mat-toolbar .mat-form-field-ripple,
.theme-darak .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-darak .mat-toolbar .mat-form-field-label,
.theme-darak .mat-toolbar .mat-focused .mat-form-field-label,
.theme-darak .mat-toolbar .mat-select-value,
.theme-darak .mat-toolbar .mat-select-arrow,
.theme-darak .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-darak .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-darak .mat-tooltip {
  background: rgba(95, 95, 95, 0.9);
}
.theme-darak .mat-tree {
  background: #393939;
}
.theme-darak .mat-tree-node,
.theme-darak .mat-nested-tree-node {
  color: white;
}
.theme-darak .mat-snack-bar-container {
  color: rgba(61, 79, 161, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-darak .mat-simple-snackbar-action {
  color: inherit;
}

.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: "Material Icons";
}
.material-icons .mat-badge-content {
  font-family: "Muli";
}

.user-info {
  width: 100%;
  text-align: start;
  padding: 15px 5px;
  display: flex;
  border-bottom: 1px solid #636b90;
}

.edura-logo {
  width: 75px;
  margin: 0px 10px;
  vertical-align: middle;
  margin-top: -8px;
}

app-website-header .website-header__wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0.75rem;
  font-size: 0.8rem;
}
app-website-header .website-header__nav {
  list-style-type: none;
  color: #3d4fa1;
}
app-website-header .website-header__nav li:not(:nth-child(6)):not(.userMenu) {
  cursor: pointer;
  outline: 0;
}
app-website-header .website-header__nav li:not(:nth-child(6)):not(.userMenu) a {
  text-decoration: none;
  font-weight: 450;
  letter-spacing: 1px;
  font-weight: bolder;
}
app-website-header .website-header__nav li:not(:nth-child(6)):not(.userMenu):not(:nth-child(6)):hover {
  padding-bottom: 6px;
  border-bottom: 2px solid #3d4fa1;
  transition: all 0.1s ease-in;
}
app-website-header .website-header__nav li.lang:hover {
  padding-bottom: 6px;
  border-bottom: 2px solid #3d4fa1;
  transition: all 0.1s ease-in;
}
app-website-header .website-header__nav li.lang a {
  font-weight: initial !important;
  cursor: pointer;
}
app-website-header .website-header__nav .userMenu {
  font-weight: bolder;
  cursor: pointer;
}
app-website-header .website-header__nav .userMenu fa-icon {
  font-size: 1em;
  padding: 0 0.5em;
}
app-website-header .website-header__sign-up {
  padding: 0.5em 1.5em;
  border-radius: 22px;
  color: #ffffff !important;
  border: 1px solid #ffffff;
  font-weight: bolder;
  outline: 0;
  background: linear-gradient(to top, #3d4fa1, #03a9f4);
  cursor: pointer;
}
app-website-header .website-header__sign-up:hover {
  background: linear-gradient(to bottom, #3d4fa1, #03a9f4);
}
app-website-header-mobile .website-header-mobile {
  background-color: rgba(3, 169, 244, 0.03);
}
app-website-header-mobile .website-header-mobile__wrapper {
  padding: 1rem;
}
app-website-header-mobile .website-header-mobile mat-icon {
  color: #3d4fa1;
  font-size: 30px;
}
app-website-header-mobile .website-header-mobile mat-sidenav {
  width: 100%;
}
app-website-header-mobile .website-header-mobile mat-nav-list {
  width: 100%;
  height: 100%;
}
app-website-header-mobile .website-header-mobile mat-nav-list a {
  font-size: 1.5rem;
  font-weight: bolder;
  margin-bottom: 1.5rem;
}

app-website-footer .website-footer {
  background-color: rgba(3, 169, 244, 0.03);
  padding: 2em 0;
}
app-website-footer .website-footer__wrapper {
  max-width: 1024px;
  margin: 0 auto;
}
app-website-footer .website-footer__subscribe p {
  color: #3d4fa1;
  font-size: 0.8rem;
}
app-website-footer .website-footer__subscribe p:nth-of-type(2) {
  color: rgba(61, 79, 161, 0.8);
  font-size: 0.85rem;
  font-weight: 600;
}
app-website-footer .website-footer__subscribe button {
  color: #ffffff;
  background: linear-gradient(to top, #3d4fa1, #03a9f4);
  cursor: pointer;
  border: 0;
  padding: 0.8em 1.6em;
  outline: 0;
}
app-website-footer .website-footer__subscribe button:hover {
  background: linear-gradient(to bottom, #3d4fa1, #03a9f4);
}
app-website-footer .website-footer__subscribe input {
  font-size: 1.2em;
  display: block;
  border: 1px solid rgba(128, 128, 128, 0.5);
  padding: 0.5em 1em;
  outline: 0;
  width: 60%;
}
@media screen and (max-width: 959px) {
  app-website-footer .website-footer__subscribe {
    padding: 0 0.5em;
    text-align: center;
  }
  app-website-footer .website-footer__subscribe input {
    width: 50%;
  }
}
app-website-footer .website-footer h6 {
  font-size: 1rem;
  margin: 1em 0;
  color: rgba(61, 79, 161, 0.8);
}
app-website-footer .website-footer ul {
  list-style-type: none;
  padding: 0;
  text-align: center;
}
app-website-footer .website-footer ul li {
  margin-bottom: 1.5em;
  font-size: 0.75rem;
  color: rgba(61, 79, 161, 0.8);
}
app-website-footer .website-footer ul li span {
  margin-right: 0.5em;
}
app-website-footer .website-footer ul li a {
  text-decoration: none;
}
app-website-footer .website-footer ul li a:hover {
  text-decoration: underline;
  font-weight: 600;
}
app-website-footer .website-footer__get-in-touch ul li {
  color: black;
  font-weight: 700;
}
app-website-footer .website-footer__get-in-touch ul li .material-icons {
  font-weight: initial;
  color: rgba(61, 79, 161, 0.8);
}
app-website-footer .social-icons {
  padding: 1em;
  font-size: 1.7em;
  color: rgba(128, 128, 128, 0.5);
  text-align: center;
}
app-website-footer .social-icons fa-icon {
  margin-right: 1em;
  cursor: pointer;
}
app-website-footer .social-icons fa-icon:nth-child(1):hover {
  color: rgba(61, 79, 161, 0.85);
  transition: all 0.5s ease;
}
app-website-footer .social-icons fa-icon:nth-child(2):hover {
  color: rgba(255, 0, 0, 0.85);
  transition: all 0.5s ease;
}
app-website-footer .social-icons fa-icon:nth-child(3):hover {
  color: rgba(3, 169, 244, 0.85);
  transition: all 0.5s ease;
}

app-website-home .website-home__main-section {
  display: none;
  max-width: 1200px;
  margin: 0 auto;
  padding: 4em 0 10em 0;
}
@media screen and (max-width: 959px) {
  app-website-home .website-home__main-section {
    text-align: center;
    color: black;
  }
}
@media screen and (max-width: 599px) {
  app-website-home .website-home__main-section {
    font-size: 7px;
    padding: 1em 0 12em 0;
    text-align: center;
  }
}
app-website-home .website-home__main-section__intro h1 {
  font-size: 6em;
  text-align: start;
  letter-spacing: 2px;
  color: #1fb7d5;
  margin: 0 !important;
  font-weight: 900;
}
app-website-home .website-home__main-section__intro p {
  letter-spacing: 0;
  line-height: 24px;
  font-size: 16px;
  margin: 0;
  color: #777;
  padding: 15px 0;
  font-weight: 400 !important;
}
@media screen and (max-width: 599px) {
  app-website-home .website-home__main-section__intro p {
    color: black;
    padding: 0 1em;
  }
}
app-website-home .website-home__main-section__intro__try-now {
  color: #ffffff;
  letter-spacing: 1px;
  font-size: 14px;
  text-transform: capitalize;
  padding: 15px 60px;
  outline: 0;
  background: #1fb7d5;
  border-radius: 30px;
  border: 0;
  cursor: pointer;
}
app-website-home .website-home__main-section__intro__store img {
  outline: 0;
  cursor: pointer;
}
app-website-home .website-home__main-section__slider-image {
  cursor: pointer;
}
app-website-home .website-home__main-section__slider-image img {
  width: 100%;
  height: 100%;
}
app-website-home .website-home__statistics {
  background-color: #fff;
  max-width: 1024px;
  margin: 0 auto;
  box-shadow: 1px 1px 1px 1px #e7e7f4;
  padding: 4em 3em;
  margin-top: -6em;
}
app-website-home .website-home__statistics h6 {
  color: #db4488;
  font-size: 2.5em;
  margin: 0;
}
app-website-home .website-home__statistics p {
  color: rgba(0, 0, 0, 0.75);
  font-size: 1.7em;
  font-weight: 600;
  margin: 0;
  margin-top: 0.5em;
}
app-website-home .website-home__statistics fa-icon {
  color: #273685;
  font-size: 4em;
}
@media screen and (max-width: 599px) {
  app-website-home .website-home__statistics__item {
    margin-bottom: 2em;
    width: 100%;
  }
}
app-website-home .website-home__online-resources {
  max-width: 1024px;
  margin: 0 auto;
  padding: 4em 1em;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
}
@media screen and (max-width: 599px) {
  app-website-home .website-home__online-resources {
    font-size: 8px;
  }
}
app-website-home .website-home__online-resources h2 {
  text-align: center;
  font-size: 3em;
  letter-spacing: 1px;
}
app-website-home .website-home__online-resources p {
  font-size: 1.5em;
  line-height: 1.7;
}
@media screen and (max-width: 599px) {
  app-website-home .website-home__online-resources p {
    text-align: center;
  }
}
app-website-home .website-home__cards {
  max-width: 1270px;
  margin: 0 auto;
  padding: 0 1em;
}
app-website-home .website-home__cards div {
  box-shadow: 1px 1px 1px 2px #e7e7f4;
  text-align: center;
  padding: 1.5em 1em 1em;
  background-color: rgba(3, 169, 244, 0.03);
}
app-website-home .website-home__cards div:not(:last-of-type) {
  margin-right: 1.7em;
}
@media screen and (max-width: 599px) {
  app-website-home .website-home__cards div {
    margin: 1.7em;
    color: rgba(0, 0, 0, 0.65);
  }
}
app-website-home .website-home__cards div h4 {
  font-size: 1.7em;
  color: rgba(0, 0, 0, 0.65);
}
app-website-home .website-home__cards div p {
  font-size: 1.4em;
  line-height: 1.6;
  font-weight: 550;
}
app-website-home .website-home__learning {
  max-width: 1024px;
  margin: 0 auto;
  text-align: center;
  padding: 1em;
  overflow: hidden;
}
app-website-home .website-home__learning h2 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 3em;
  margin: 2em;
  letter-spacing: 1px;
}
@media screen and (max-width: 599px) {
  app-website-home .website-home__learning h2 {
    margin: 1em;
  }
}
app-website-home .website-home__learning__item h3 {
  font-size: 1.5em;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.75);
}
app-website-home .website-home__learning__item__top-circle {
  background-color: #cd2d6b;
  border-radius: 50%;
  padding: 2.4em;
  display: flex;
  justify-content: center;
  align-content: center;
}
app-website-home .website-home__learning__item__top-circle fa-icon {
  margin: 0;
  display: block;
  color: #fff;
  font-size: 3.5em;
  padding: 0.75em;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}
app-website-home .website-home__learning__item__bottom-circle span {
  color: #fff;
  font-weight: 700;
  font-size: 3em;
  display: block;
  background-color: #cd2d6b;
  border-radius: 50%;
  padding: 0.5em;
}
@media screen and (max-width: 599px) {
  app-website-home .website-home__learning__item__bottom-circle {
    margin-bottom: 2em;
  }
}
app-website-home .website-home__learning__item:nth-of-type(1) div {
  background-color: #273685;
}
app-website-home .website-home__learning__item:nth-of-type(1) div fa-icon {
  background-color: #3d4fa1;
}
app-website-home .website-home__learning__item:nth-of-type(1) p {
  border: 1px solid #3d4fa1;
  border-radius: 50%;
  padding: 1em;
}
app-website-home .website-home__learning__item:nth-of-type(1) p span {
  background-color: #273685;
}
app-website-home .website-home__learning__item:nth-of-type(2) div {
  background-color: #cd2d6b;
}
app-website-home .website-home__learning__item:nth-of-type(2) div fa-icon {
  background-color: #db4488;
}
app-website-home .website-home__learning__item:nth-of-type(2) p {
  border: 1px solid #db4488;
  border-radius: 50%;
  padding: 1em;
}
app-website-home .website-home__learning__item:nth-of-type(2) p span {
  background-color: #db4488;
}
app-website-home .website-home__learning__item:nth-of-type(3) div {
  background-color: #038fce;
}
app-website-home .website-home__learning__item:nth-of-type(3) div fa-icon {
  background-color: #03a9f4;
}
app-website-home .website-home__learning__item:nth-of-type(3) p {
  border: 1px solid #038fce;
  border-radius: 50%;
  padding: 1em;
}
app-website-home .website-home__learning__item:nth-of-type(3) p span {
  background-color: #03a9f4;
}
app-website-home .website-home__learning__item:nth-of-type(4) div {
  background-color: #273685;
}
app-website-home .website-home__learning__item:nth-of-type(4) div fa-icon {
  background-color: #3d4fa1;
}
app-website-home .website-home__learning__item:nth-of-type(4) p {
  border: 1px solid #3d4fa1;
  border-radius: 50%;
  padding: 1em;
}
app-website-home .website-home__learning__item:nth-of-type(4) p span {
  background-color: #273685;
}
app-website-home .website-home__reports {
  background-color: rgba(3, 169, 244, 0.03);
  margin-top: 2em;
}
app-website-home .website-home__reports__wrapper {
  max-width: 1240px;
  margin: 0 auto;
  padding: 6em 1em 12em;
}
@media screen and (max-width: 599px) {
  app-website-home .website-home__reports__wrapper {
    font-size: 7px;
    padding: 2em 1em 6em;
  }
}
app-website-home .website-home__reports h3 {
  text-align: center;
  letter-spacing: 1px;
  line-height: 1.7;
  color: #273685;
  font-size: 3.1em;
}
@media screen and (max-width: 599px) {
  app-website-home .website-home__reports h3 {
    width: 100%;
  }
}
app-website-home .website-home__reports ul {
  margin: 0;
  list-style-position: inside;
  font-size: 2em;
  font-weight: 600;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.75);
}
app-website-home .website-home__reports ul li {
  margin-bottom: 1.5em;
}
app-website-home .website-home__reports__image img {
  height: 100%;
  width: 100%;
}
app-website-home .website-home__features {
  max-width: 1024px;
  margin: 0 auto;
  color: #fff;
  padding: 4em 1em;
}
app-website-home .website-home__features fa-icon {
  font-size: 3em;
}
app-website-home .website-home__features h3 {
  font-size: 1.75em;
  font-weight: 450;
  letter-spacing: 1px;
}
app-website-home .website-home__features p {
  font-size: 1.2em;
  line-height: 1.6;
  letter-spacing: 0.7px;
}
app-website-home .website-home__features__question-bank {
  text-align: center;
  padding: 4em 2em;
  margin: 4em 3em 0 0;
  background: linear-gradient(145deg, #3d4fa1, #03a9f4);
}
@media screen and (max-width: 599px) {
  app-website-home .website-home__features__question-bank {
    margin-right: 0 !important;
  }
}
app-website-home .website-home__features__electronic-exam {
  text-align: center;
  padding: 4em 2em;
  margin: 4em 3em 0 0;
  background: linear-gradient(45deg, #3d4fa1, #5b6dc1);
}
@media screen and (max-width: 599px) {
  app-website-home .website-home__features__electronic-exam {
    margin-right: 0 !important;
  }
}
app-website-home .website-home__features__electronic-exam ul {
  text-align: initial;
  margin: 0;
  list-style-position: inside;
}
app-website-home .website-home__features__electronic-exam ul li {
  font-size: 1.2em;
  margin-bottom: 1em;
  letter-spacing: 0.5px;
}
app-website-home .website-home__features__electronic-store {
  text-align: center;
  padding: 4em 2em;
  margin: 4em 3em 0 0;
  background: linear-gradient(145deg, #cd2d6b, #db4488);
}
@media screen and (max-width: 599px) {
  app-website-home .website-home__features__electronic-store {
    margin-right: 0 !important;
  }
}
app-website-home .website-home__features__electronic-library {
  text-align: center;
  padding: 4em 2em;
  margin: 18em 3em 0 0;
  background: linear-gradient(145deg, #0391d1, rgba(3, 169, 244, 0.75));
}
@media screen and (max-width: 599px) {
  app-website-home .website-home__features__electronic-library {
    margin-right: 0 !important;
    margin-top: 4em;
  }
}
app-website-home .website-home__features__loyalty-program {
  width: 70%;
  text-align: center;
  padding: 4em 2em;
  margin-top: 6em;
  background: linear-gradient(45deg, #0391d1, rgba(3, 169, 244, 0.75));
}
@media screen and (max-width: 599px) {
  app-website-home .website-home__features__loyalty-program {
    width: 100%;
  }
}
app-website-home .website-home__features__edura-programs {
  width: 75%;
  margin-left: auto;
  margin-top: 14em;
  color: rgba(0, 0, 0, 0.7);
}
@media screen and (max-width: 599px) {
  app-website-home .website-home__features__edura-programs {
    width: 100%;
    margin-top: 0;
    text-align: center;
  }
}
app-website-home .website-home__features__edura-programs h2 {
  font-size: 3em;
  letter-spacing: 1px;
  font-weight: 600;
}
app-website-home .website-home__features__edura-programs p {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
}
app-website-home .website-home__video__wrapper {
  max-width: 1024px;
  margin: 4em auto;
}
@media screen and (max-width: 599px) {
  app-website-home .website-home__video__wrapper {
    font-size: 9px;
    margin-top: 0;
  }
}
app-website-home .website-home__video__wrapper h2 {
  text-align: center;
  font-size: 2.5em;
  letter-spacing: 1px;
  line-height: 1.6;
  color: rgba(0, 0, 0, 0.75);
  margin-right: 2em;
}
@media screen and (max-width: 599px) {
  app-website-home .website-home__video__wrapper h2 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}
app-website-home .website-home__store {
  background-image: url("./assets/images/store.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
@media screen and (max-width: 599px) {
  app-website-home .website-home__store {
    font-size: 8px;
    text-align: center;
  }
}
app-website-home .website-home__store__wrapper {
  max-width: 1024px;
  margin: 0 auto;
  padding: 4em 1em;
}
app-website-home .website-home__store__content h3 {
  font-size: 3em;
  letter-spacing: 2px;
  margin-bottom: 0.5em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.75);
}
app-website-home .website-home__store__content p {
  font-size: 1.4em;
  margin-bottom: 2.5em;
  color: rgba(0, 0, 0, 0.75);
  font-weight: 650;
}
app-website-home .website-home__store__content img {
  cursor: pointer;
}
@media screen and (max-width: 599px) {
  app-website-home .website-home__store__content img {
    margin: 0 auto;
    margin-bottom: 3em;
  }
}
app-website-home .website-home__store__image img {
  height: 100%;
  width: 100%;
}

app-login .website-login {
  background-image: url("./assets/images/bglogin.jpg");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}
app-login .website-login__wrapper {
  margin: 0 auto;
  background-color: rgba(48, 183, 215, 0.41);
  padding: 7em 0 7em;
}
@media screen and (max-width: 599px) {
  app-login .website-login__wrapper {
    padding: 3em 0 3em;
  }
}
app-login .website-login__container {
  padding: 0 2em;
  border-radius: 5px;
  background-color: #fff;
  opacity: 0.8;
}
app-login .website-login__container img {
  height: 100px;
  width: 100px;
}
app-login .website-login__container a {
  color: #60ccfd;
  font-size: 1.3em;
  text-decoration: none;
}
app-login .website-login__container a:hover {
  color: #0286c2;
}
app-login .website-login__container mat-icon {
  color: #60ccfd;
}
app-login .website-login__container mat-form-field {
  font-size: 1.3em;
  padding-bottom: 0;
}
app-login .website-login__container mat-form-field input {
  padding-bottom: 0.5em;
  padding-left: 0.5em;
}
app-login .website-login__container button {
  color: #fff !important;
  font-size: 1.8em;
  text-transform: uppercase;
  margin: 1em 0 0.5em;
  letter-spacing: 1px;
}
app-login .website-login__container button:hover:not(:disabled) {
  background: linear-gradient(to right, #03a9f4, #0286c2);
  transition: all 0.5s ease-in;
}
app-login .website-login__welcome-back {
  font-size: 1.5em;
  letter-spacing: 1px;
}
app-login .website-login__sign-up {
  font-size: 1.3em;
}
app-login .website-login__sign-up a {
  font-size: 1em;
  cursor: pointer;
}

app-forget-password .website-forget-password {
  background-image: url("./assets/images/bglogin.jpg");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}
app-forget-password .website-forget-password__wrapper {
  margin: 0 auto;
  background-color: rgba(48, 183, 215, 0.41);
  padding: 7em 0 7em;
}
@media screen and (max-width: 599px) {
  app-forget-password .website-forget-password__wrapper {
    padding: 3em 0 3em;
    font-size: 8px;
  }
}
app-forget-password .website-forget-password__container {
  padding: 0 2em;
  border-radius: 5px;
  background-color: #fff;
  opacity: 0.8;
}
app-forget-password .website-forget-password__container * + * {
  margin-bottom: 1em;
}
app-forget-password .website-forget-password__container img {
  height: 100px;
  width: 100px;
  margin-bottom: 1.5em;
}
app-forget-password .website-forget-password__container mat-icon {
  color: #60ccfd;
}
app-forget-password .website-forget-password__container mat-form-field {
  font-size: 1.3em;
  padding-bottom: 0;
}
app-forget-password .website-forget-password__container mat-form-field input {
  padding-bottom: 0.5em;
  padding-left: 0.5em;
}
app-forget-password .website-forget-password__container button {
  background: #60ccfd;
  color: #fff !important;
  font-size: 1.8em;
  text-transform: uppercase;
  margin-bottom: 0.5em;
  letter-spacing: 1px;
}
@media screen and (max-width: 790px) {
  app-forget-password .website-forget-password__container button {
    font-size: 10px;
  }
}
app-forget-password .website-forget-password__container button:hover:not(:disabled) {
  background: linear-gradient(to right, #03a9f4, #0286c2);
  transition: all 0.5s ease-in;
}

app-register .website-register {
  background-image: url("/assets/images/bglogin.jpg");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}
app-register .website-register__wrapper {
  margin: 0 auto;
  background-color: rgba(48, 183, 215, 0.41);
  padding: 2.5em 2em;
}
app-register .website-register__container {
  padding: 0;
  background-color: #fff;
  opacity: 0.8;
}
app-register .website-register__container img {
  width: 60%;
  height: 60%;
}

app-address-form {
  font-size: 1.2em;
}
app-address-form form {
  padding: 2em 0;
}
@media screen and (max-width: 599px) {
  app-address-form button {
    margin-bottom: 1em !important;
  }
}

app-basic-info-form {
  font-size: 1.2em;
}
app-basic-info-form form {
  padding: 2em 0;
}
app-basic-info-form form mat-radio-button {
  margin-top: 0.5em;
  margin-bottom: 1em;
}
@media screen and (max-width: 599px) {
  app-basic-info-form button {
    margin-bottom: 1em !important;
  }
}

app-education-form {
  font-size: 1.2em;
}
app-education-form form {
  padding: 2em 0;
}
@media screen and (max-width: 599px) {
  app-education-form button {
    margin-bottom: 1em !important;
  }
}

:host {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

mat-drawer-container {
  height: 100%;
}

mat-drawer-content {
  display: flex;
  flex-direction: column;
  background-color: rgba(128, 128, 128, 0.1);
  overflow: auto;
}
mat-drawer-content .content {
  padding: 1em 2em;
  background: #eee;
}

mat-drawer {
  width: 250px;
}

mat-select .mat-select-placeholder {
  padding-bottom: 0 !important;
  color: #fff;
}

.table-wrapper {
  position: relative;
}
.table-wrapper mat-table {
  position: relative;
  border-radius: 10px 10px 0 0;
}
.table-wrapper mat-table mat-header-row {
  background-color: #e6e6ec !important;
  border-radius: 0 0 0 !important;
}
.table-wrapper mat-table mat-row {
  transition: all 0.1s ease-out;
  outline: 0;
}
.table-wrapper mat-table mat-row:hover {
  background-color: rgba(0, 0, 255, 0.125);
  font-weight: bold;
}

.no-data-found {
  margin: 0 auto;
  padding: 2em 0;
  width: 200px;
  height: 200px;
}
.no-data-found img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.actions {
  cursor: pointer;
  outline: 0;
  border: 0;
}

.actions-menu .mat-menu-content {
  padding: 0 !important;
}
.actions-menu button:hover {
  color: #fff;
  background-color: #3d4fa1 !important;
  transition: all 0.5s ease-in;
}

.label--success {
  color: #3d4fa1;
}
.label--error {
  color: rgba(128, 128, 128, 0.5);
}

.status {
  border-radius: 5px;
  padding: 4px 8px;
  font-size: 0.9em;
  letter-spacing: 1px;
  font-weight: 700;
}
.status--active {
  color: rgba(0, 128, 0, 0.6);
}
.status--block {
  color: #cc0000;
}
.status--suspended {
  color: rgba(128, 128, 128, 0.5);
}
.status--requested {
  color: #3d4fa1;
}
.status--pending {
  color: rgba(61, 79, 161, 0.5);
}
.status--not-paid {
  background-color: rgba(255, 165, 0, 0.85);
}

.table-image {
  padding: 2px;
  border: 1px solid;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  object-fit: cover;
  object-position: center;
}

.attachment-image {
  padding: 3px;
  border: 1px solid;
  width: 75px;
  height: 75px;
  border-radius: 15px;
  cursor: pointer;
  margin: 0 10px;
}
.attachment-image img {
  border-radius: 15px;
  width: 100%;
  height: 100%;
}

.attachment-video {
  color: rgba(0, 0, 0, 0.75);
  font-size: 90px;
  width: 90px;
  height: 90px;
  margin: 0 10px;
}

.attachment-text {
  color: rgba(255, 0, 0, 0.75);
  font-size: 90px;
  width: 90px;
  height: 90px;
  margin: 0 10px;
  cursor: pointer;
}

.course-hours {
  display: inline-block;
  background-color: rgba(0, 0, 255, 0.5);
  padding: 0.5em 1em;
  border-radius: 15px;
  color: #fff;
  margin: 5px;
}

.breadcrumbs__container {
  display: inline-block;
  list-style-type: none;
  margin: 2em 0;
  padding: 0;
  letter-spacing: 1px;
}
.breadcrumbs__container .breadcrumbs__item {
  margin: 0 0.5em;
  padding: 0px;
  display: inline-block;
  font-size: 2em;
  font-weight: bold;
  border-radius: 0;
  background-color: #fff;
  cursor: pointer;
  border: 0px solid #3d4fa1;
  transition: all 500ms ease-in;
}
.breadcrumbs__container .breadcrumbs__item a {
  text-decoration: none;
  width: 100%;
  height: 100%;
}
.breadcrumbs__container .breadcrumbs__item:hover {
  color: #fff;
  background-color: #3d4fa1;
}
.breadcrumbs__container .breadcrumbs__item--active {
  color: #333;
  background-color: #fff;
}
.breadcrumbs__container .breadcrumbs__item--active a {
  text-decoration: none !important;
}

.timer {
  text-align: center;
  font-size: 3em;
  margin: 1em 0;
  color: rgba(255, 0, 0, 0.75);
  font-weight: bold;
}

app-dashboard-sidebar .dashboard-sidebar {
  color: #fff;
}
app-dashboard-sidebar .dashboard-sidebar__logo {
  cursor: pointer;
  outline: 0;
  height: 48px;
}
app-dashboard-sidebar .dashboard-sidebar__logo img {
  height: 100%;
  width: 100%;
}
app-dashboard-sidebar .dashboard-sidebar__profile {
  width: 60px;
  height: 60px;
}
app-dashboard-sidebar .dashboard-sidebar__profile img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 0px solid #fff;
  padding: 0px;
  object-position: center;
  object-fit: cover;
}
app-dashboard-sidebar .dashboard-sidebar ul {
  list-style-type: none;
  margin: 1em 0 0;
  padding: 0;
  width: 100%;
}
app-dashboard-sidebar .dashboard-sidebar ul li {
  margin: 0px 8px;
  border-radius: 0px;
  padding: 8px;
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 14px;
  appearance: none;
  outline: none;
}
app-dashboard-sidebar .dashboard-sidebar ul li mat-accordion mat-expansion-panel {
  box-shadow: none !important;
  color: #fff;
  background-color: transparent;
  padding-bottom: 0;
}
app-dashboard-sidebar .dashboard-sidebar ul li mat-accordion mat-expansion-panel a {
  padding: 1em 0;
}
app-dashboard-sidebar .dashboard-sidebar ul li mat-accordion mat-expansion-panel mat-expansion-panel-header {
  width: 100%;
  padding: 0;
}
app-dashboard-sidebar .dashboard-sidebar ul li mat-accordion mat-expansion-panel mat-expansion-panel-header mat-panel-title {
  color: #fff;
}
app-dashboard-sidebar .dashboard-sidebar ul li mat-accordion mat-expansion-panel .mat-expansion-panel-body {
  padding-bottom: 0;
}
app-dashboard-sidebar .dashboard-sidebar ul li.expansion-list {
  width: 100%;
  padding: 0 0.5em;
}
app-dashboard-sidebar .dashboard-sidebar ul li.expansion-list:hover {
  background-color: rgba(61, 79, 161, 0.2) !important;
}
app-dashboard-sidebar .dashboard-sidebar ul li.expansion-list .expansion-item {
  width: 100%;
}
app-dashboard-sidebar .dashboard-sidebar ul li.expansion-list .expansion-item:hover {
  background-color: #3d4fa1;
}
app-dashboard-sidebar .dashboard-sidebar ul li a {
  text-decoration: none;
  color: inherit;
}
app-dashboard-sidebar .dashboard-sidebar ul li mat-icon {
  margin: 0 0.5em;
  font-size: 20px;
}
app-dashboard-sidebar .dashboard-sidebar ul li:hover {
  transition: all 0.3s ease-in;
  background-color: #fff;
  transition: all 0.3s ease-in;
  color: #323e73;
}
app-dashboard-sidebar .dashboard-sidebar ul .active-item {
  background-color: #fff;
  transition: all 0.3s ease-in;
  color: #323e73;
}
app-dashboard-sidebar .dashboard-sidebar h4 {
  margin-bottom: 0;
  font-size: 14px;
  margin: 0;
  padding: 15px 5px 5px;
}
app-dashboard-sidebar .dashboard-sidebar p {
  color: #fff;
  padding: 5px 5px 0px;
  margin: 0;
  font-size: 12px;
}
app-dashboard-sidebar .dashboard-sidebar img {
  width: 100%;
  height: 100%;
}
app-dashboard-sidebar .dashboard-sidebar figure {
  padding: 0;
  margin: 5px 10px;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(61, 79, 161, 0.5);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(61, 79, 161, 0.8);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(39, 54, 133, 0.75);
}

.active-item {
  background-color: #3d4fa1;
}

app-dashboard-header .dashboard-header {
  padding: 1em;
  box-shadow: 1px 1px 2px 2px rgba(61, 79, 161, 0.25);
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}
app-dashboard-header .dashboard-header .flag-icon {
  font-size: 3em;
}
app-dashboard-header .dashboard-header .userMenu {
  font-size: 1.4em;
  letter-spacing: 1px;
  cursor: pointer;
}
app-dashboard-header .dashboard-header figure {
  margin: 0 1em;
  padding: 0;
  height: 40px;
  width: 40px;
}
app-dashboard-header .dashboard-header figure img {
  height: 100%;
  width: 100%;
  padding: 2px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  border: 0px solid rgba(61, 79, 161, 0.75);
}

.list {
  width: 100%;
  display: inline-block;
  padding: 40px 0;
  background-color: #fff;
}

.toastr-success {
  background: rgba(0, 128, 0, 0.75);
  color: #fff;
}

.toastr-error {
  background: rgba(255, 0, 0, 0.57);
  color: #fff;
}

.mat-form-field .mat-form-field-wrapper {
  margin-bottom: 1.25em;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

mat-radio-group {
  font-size: 1.2em;
}
mat-radio-group mat-radio-button {
  margin: 0 0.5em;
}
mat-radio-group mat-radio-button .mat-radio-label-content {
  margin: 0 !important;
}

html[dir=rtl] {
  direction: rtl;
  text-align: right;
  font-family: Cairo;
}
html[dir=rtl] * > * {
  letter-spacing: initial !important;
}
html[dir=rtl] app-website-footer .website-footer li span {
  margin-right: 0;
  margin-left: 0.5em;
}
html[dir=rtl] app-website-home .website-home__main-section__intro__try-now {
  padding: 0.7em 2.3em;
}
html[dir=rtl] app-website-home .website-home__cards div:not(:last-of-type) {
  margin-right: 0;
  margin-left: 1.7em;
}
@media screen and (max-width: 599px) {
  html[dir=rtl] app-website-home .website-home__cards div:not(:last-of-type) {
    margin: 1em 1.7em;
  }
}
html[dir=rtl] app-website-home .website-home__learning__item__bottom-circle span {
  padding: 0.8em;
}
html[dir=rtl] app-website-home .website-home__features__question-bank {
  margin-right: 0;
  margin-left: 3em;
}
@media screen and (max-width: 599px) {
  html[dir=rtl] app-website-home .website-home__features__question-bank {
    margin-left: 0 !important;
  }
}
html[dir=rtl] app-website-home .website-home__features__electronic-exam {
  margin-right: 0;
  margin-left: 3em;
}
@media screen and (max-width: 599px) {
  html[dir=rtl] app-website-home .website-home__features__electronic-exam {
    margin-left: 0 !important;
  }
}
html[dir=rtl] app-website-home .website-home__features__electronic-library {
  margin-right: 0;
  margin-left: 3em;
}
@media screen and (max-width: 599px) {
  html[dir=rtl] app-website-home .website-home__features__electronic-library {
    margin-left: 0 !important;
  }
}
html[dir=rtl] app-website-home .website-home__features__electronic-store {
  margin-right: 0;
  margin-left: 3em;
}
@media screen and (max-width: 599px) {
  html[dir=rtl] app-website-home .website-home__features__electronic-store {
    margin-left: 0 !important;
  }
}
html[dir=rtl] app-website-home .website-home__features__edura-programs {
  margin-right: auto;
  margin-left: 0;
}
@media screen and (max-width: 599px) {
  html[dir=rtl] app-website-home .website-home__features__edura-programs {
    margin-left: 0 !important;
  }
}
html[dir=rtl] app-website-home .website-home__video h2 {
  margin-left: 2em;
  margin-right: 0 !important;
}
html[dir=rtl] .roles__card {
  justify-content: flex-start !important;
}
html[dir=rtl] .roles__card h4 {
  font-size: 2em !important;
  margin-bottom: 0.75em !important;
}

.bg-primary-colour {
  background-color: var(--dynamic-primary-colour);
}

.bg-secondary-colour {
  background-color: var(--dynamic-secondary-colour);
}

.text-primary-colour {
  color: var(--dynamic-primary-colour);
}

.text-secondary-colour {
  color: var(--dynamic-secondary-colour);
}

html[dir=ltr] mat-drawer-content {
  margin-right: 0 !important;
}

html[dir=rtl] {
  direction: rtl;
  text-align: right;
  font-family: Cairo;
  overflow: auto;
}
html[dir=rtl] mat-drawer-content {
  margin-left: 0 !important;
  margin-right: 250px;
}

body {
  padding: 0;
  margin: 0;
  overflow: auto;
  font-family: "Roboto", sans-serif;
  overflow: auto !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
button,
a,
div {
  font-family: "Roboto", sans-serif;
}

*,
:after,
:before {
  box-sizing: border-box;
}

.mx-15 {
  margin: 15px 0;
}

@media screen and (max-width: 768px) {
  mat-drawer {
    visibility: hidden;
  }

  mat-drawer-content {
    margin-left: 0;
  }
}
.first-char {
  width: 35px;
  height: 34px;
  border-radius: 50%;
  background: #d1eaef;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #229bb3;
}

mat-radio-group mat-radio-button .mat-radio-label-content {
  margin: 0 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

/*===  page title  =====  =*/
.page-title h3 {
  color: #333;
  font-size: 22px;
  margin: 0;
  padding: 10px 0;
}

/*===  page title  =====  =*/
.card-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #eee;
}
.card-filter .btn-add {
  margin: 0;
  color: #fff;
  box-shadow: none;
  font-size: 14px;
  border-radius: 0px;
}
.card-filter .mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0;
  padding: 0;
}

/*===  card-data  =====  =*/
.card-data {
  background: #fff;
  display: flex;
  justify-content: space-around;
  padding: 0px;
  margin: 0px 5px 15px;
  color: #777;
  border-radius: 0px;
  align-items: center;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
}
.card-data h3 {
  font-size: 28px;
  margin: 15px 0 0;
}
.card-data p {
  font-size: 18px;
  margin: 15px 0;
}
.card-data mat-icon {
  font-size: 26px;
  display: initial;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 0;
  padding: 10px;
}

/*===  mat-tab mat-tab=====  =*/
.mat-tab-labels {
  display: flex;
  border-radius: 0px;
}

.mat-tab-label {
  opacity: 1 !important;
  height: 40px !important;
}
.mat-tab-label .mat-tab-label-content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  color: #777;
  font-size: 14px;
  opacity: 1;
}

.mat-tab-label-active {
  border-radius: 0px;
  border-bottom: 3px solid #3d4fa1;
}
.mat-tab-label-active .mat-tab-label-content {
  color: #3d4fa1;
  font-weight: 600;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: transparent !important;
  height: 0px;
}

/* ==========  Table =================== */
.matrial-table {
  padding: 1px;
}

mat-row {
  min-height: 32px;
}

.mat-cell {
  color: #777;
  font-size: 14px;
}
.mat-cell p {
  padding: 0;
  margin: 0;
}

mat-header-row {
  min-height: 55px !important;
  background: #3d4fa1;
  color: #fff !important;
  min-height: 42px !important;
  background: #e6e6ec;
  color: #000 !important;
  border-radius: 0px;
}

.mat-header-cell {
  color: #000;
  font-size: 14px;
}

mat-radio-group {
  font-size: 1.2em;
  padding: 10px;
}

.mat-elevation-z8 {
  box-shadow: none;
}

tr.mat-header-row {
  background-color: #3d4fa1 !important;
  border-radius: 0 0 0 !important;
}

#zmmtg-root {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent !important;
  bottom: 0 !important;
  right: 0 !important;
  z-index: 99 !important;
}

.full-width-field {
  width: 100%;
}

.timepicker__main-content {
  direction: initial !important;
}