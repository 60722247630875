@mixin website-home-theme($theme) {
  app-website-home {
    .website-home {
      &__main-section {
        display: none;
        max-width: 1200px;
        margin: 0 auto;
        padding: 4em 0 10em 0;

        @media screen and (max-width: 959px) {
          text-align: center;
          color: black;
        }

        @media screen and (max-width: 599px) {
          font-size: 7px;
          padding: 1em 0 12em 0;
          text-align: center;
        }

        &__wrapper {
          // background-color: rgba($light-blue-text, 0.03);
        }

        &__intro {
          h1 {
            font-size: 6em;
            text-align: start;
            letter-spacing: 2px;
            color: #1fb7d5;
            margin: 0 !important;
            font-weight: 900;
          }

          p {
            letter-spacing: 0;
            line-height: 24px;
            font-size: 16px;
            margin: 0;
            color: #777;
            padding: 15px 0;
            font-weight: 400 !important;

            @media screen and (max-width: 599px) {
              color: black;
              padding: 0 1em;
            }
          }

          &__try-now {
            color: #ffffff;
            letter-spacing: 1px;
            font-size: 14px;
            text-transform: capitalize;
            padding: 15px 60px;
            outline: 0;
            background: #1fb7d5;
            border-radius: 30px;
            border: 0;
            cursor: pointer;
          }

          &__store {
            img {
              outline: 0;
              cursor: pointer;
            }
          }
        }

        &__slider-image {
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      &__statistics {
        background-color: #fff;
        max-width: 1024px;
        margin: 0 auto;
        box-shadow: 1px 1px 1px 1px #e7e7f4;
        padding: 4em 3em;
        margin-top: -6em;

        h6 {
          color: mat-color($mat-accent, main);
          font-size: 2.5em;
          margin: 0;
        }

        p {
          color: rgba(black, 0.75);
          font-size: 1.7em;
          font-weight: 600;
          margin: 0;
          margin-top: 0.5em;
        }

        fa-icon {
          color: mat-color($mat-primary, darker);
          font-size: 4em;
        }

        &__item {
          @media screen and (max-width: 599px) {
            margin-bottom: 2em;
            width: 100%;
          }
        }
      }

      &__online-resources {
        max-width: 1024px;
        margin: 0 auto;
        padding: 4em 1em;
        text-align: center;
        color: rgba(black, 0.8);

        @media screen and (max-width: 599px) {
          font-size: 8px;
        }

        h2 {
          text-align: center;
          font-size: 3em;
          letter-spacing: 1px;
        }

        p {
          font-size: 1.5em;
          line-height: 1.7;

          @media screen and (max-width: 599px) {
            text-align: center;
          }
        }
      }

      &__cards {
        max-width: 1270px;
        margin: 0 auto;
        padding: 0 1em;

        div {
          &:not(:last-of-type) {
            margin-right: 1.7em;
          }
          box-shadow: 1px 1px 1px 2px #e7e7f4;
          text-align: center;
          padding: 1.5em 1em 1em;
          background-color: rgba($light-blue-text, 0.03);

          @media screen and (max-width: 599px) {
            margin: 1.7em;
            color: rgba($color: black, $alpha: 0.65);
          }

          h4 {
            font-size: 1.7em;
            color: rgba($color: black, $alpha: 0.65);
          }

          p {
            font-size: 1.4em;
            line-height: 1.6;
            font-weight: 550;
          }
        }
      }

      &__learning {
        max-width: 1024px;
        margin: 0 auto;
        text-align: center;
        padding: 1em;
        overflow: hidden;

        h2 {
          color: rgba(black, 0.8);
          font-size: 3em;
          margin: 2em;
          letter-spacing: 1px;

          @media screen and (max-width: 599px) {
            margin: 1em;
          }
        }

        &__item {
          h3 {
            font-size: 1.5em;
            letter-spacing: 1px;
            color: rgba($color: black, $alpha: 0.75);
          }

          &__top-circle {
            background-color: mat-color($mat-accent, darker);
            border-radius: 50%;
            padding: 2.4em;
            display: flex;
            justify-content: center;
            align-content: center;

            fa-icon {
              margin: 0;
              display: block;
              color: #fff;
              font-size: 3.5em;
              padding: 0.75em;
              box-shadow: 2px 2px 2px 1px rgba(black, 0.2);
              border-radius: 50%;
            }
          }

          &__bottom-circle {
            span {
              color: #fff;
              font-weight: 700;
              font-size: 3em;
              display: block;
              background-color: mat-color($mat-accent, darker);
              border-radius: 50%;
              padding: 0.5em;
            }

            @media screen and (max-width: 599px) {
              margin-bottom: 2em;
            }
          }
        }

        &__item:nth-of-type(1) {
          div {
            background-color: mat-color($mat-primary, darker);

            fa-icon {
              background-color: mat-color($mat-primary, main);
            }
          }

          p {
            border: 1px solid mat-color($mat-primary, main);
            border-radius: 50%;
            padding: 1em;

            span {
              background-color: mat-color($mat-primary, darker);
            }
          }
        }

        &__item:nth-of-type(2) {
          div {
            background-color: mat-color($mat-accent, darker);

            fa-icon {
              background-color: mat-color($mat-accent, main);
            }
          }

          p {
            border: 1px solid mat-color($mat-accent, main);
            border-radius: 50%;
            padding: 1em;
            span {
              background-color: mat-color($mat-accent, main);
            }
          }
        }

        &__item:nth-of-type(3) {
          div {
            background-color: darken($light-blue-text, 7.5%);

            fa-icon {
              background-color: $light-blue-text;
            }
          }

          p {
            border: 1px solid darken($light-blue-text, 7.5%);
            border-radius: 50%;
            padding: 1em;

            span {
              background-color: $light-blue-text;
            }
          }
        }

        &__item:nth-of-type(4) {
          div {
            background-color: mat-color($mat-primary, darker);

            fa-icon {
              background-color: mat-color($mat-primary, main);
            }
          }

          p {
            border: 1px solid mat-color($mat-primary, main);
            border-radius: 50%;
            padding: 1em;

            span {
              background-color: mat-color($mat-primary, darker);
            }
          }
        }
      }

      &__reports {
        background-color: rgba($light-blue-text, 0.03);
        margin-top: 2em;

        &__wrapper {
          max-width: 1240px;
          margin: 0 auto;
          padding: 6em 1em 12em;

          @media screen and (max-width: 599px) {
            font-size: 7px;
            padding: 2em 1em 6em;
          }
        }

        h3 {
          text-align: center;
          letter-spacing: 1px;
          line-height: 1.7;
          color: mat-color($mat-primary, darker);
          font-size: 3.1em;

          @media screen and (max-width: 599px) {
            width: 100%;
          }
        }

        ul {
          margin: 0;
          list-style-position: inside;
          font-size: 2em;
          font-weight: 600;
          letter-spacing: 1px;
          color: rgba(black, 0.75);

          li {
            margin-bottom: 1.5em;
          }
        }

        &__image {
          img {
            height: 100%;
            width: 100%;
          }
        }
      }

      &__features {
        max-width: 1024px;
        margin: 0 auto;
        color: #fff;
        padding: 4em 1em;

        fa-icon {
          font-size: 3em;
        }

        h3 {
          font-size: 1.75em;
          font-weight: 450;
          letter-spacing: 1px;
        }

        p {
          font-size: 1.2em;
          line-height: 1.6;
          letter-spacing: 0.7px;
        }

        &__question-bank {
          text-align: center;
          padding: 4em 2em;
          margin: 4em 3em 0 0;
          background: linear-gradient(
            145deg,
            mat-color($mat-primary, main),
            $light-blue-text
          );

          @media screen and (max-width: 599px) {
            margin-right: 0 !important;
          }
        }

        &__electronic-exam {
          text-align: center;
          padding: 4em 2em;
          margin: 4em 3em 0 0;
          background: linear-gradient(
            45deg,
            mat-color($mat-primary, main),
            lighten($dark-text, 12%)
          );

          @media screen and (max-width: 599px) {
            margin-right: 0 !important;
          }

          ul {
            text-align: initial;
            margin: 0;
            list-style-position: inside;

            li {
              font-size: 1.2em;
              margin-bottom: 1em;
              letter-spacing: 0.5px;
            }
          }
        }

        &__electronic-store {
          text-align: center;
          padding: 4em 2em;
          margin: 4em 3em 0 0;
          background: linear-gradient(
            145deg,
            mat-color($mat-accent, darker),
            mat-color($mat-accent, 200)
          );

          @media screen and (max-width: 599px) {
            margin-right: 0 !important;
          }
        }

        &__electronic-library {
          text-align: center;
          padding: 4em 2em;
          margin: 18em 3em 0 0;
          background: linear-gradient(
            145deg,
            darken($light-blue-text, 7%),
            rgba($light-blue-text, 0.75)
          );

          @media screen and (max-width: 599px) {
            margin-right: 0 !important;
            margin-top: 4em;
          }
        }

        &__loyalty-program {
          width: 70%;
          text-align: center;
          padding: 4em 2em;
          margin-top: 6em;
          background: linear-gradient(
            45deg,
            darken($light-blue-text, 7%),
            rgba($light-blue-text, 0.75)
          );

          @media screen and (max-width: 599px) {
            width: 100%;
          }
        }

        &__edura-programs {
          width: 75%;
          margin-left: auto;
          margin-top: 14em;
          color: rgba(black, 0.7);

          @media screen and (max-width: 599px) {
            width: 100%;
            margin-top: 0;
            text-align: center;
          }

          h2 {
            font-size: 3em;
            letter-spacing: 1px;
            font-weight: 600;
          }

          p {
            font-weight: 600;
            color: rgba(black, 0.6);
          }
        }
      }

      &__video {
        &__wrapper {
          max-width: 1024px;
          margin: 4em auto;

          @media screen and (max-width: 599px) {
            font-size: 9px;
            margin-top: 0;
          }

          h2 {
            text-align: center;
            font-size: 2.5em;
            letter-spacing: 1px;
            line-height: 1.6;
            color: rgba(black, 0.75);
            margin-right: 2em;
            @media screen and (max-width: 599px) {
              margin-right: 0 !important;
              margin-left: 0 !important;
            }
          }
        }
      }

      &__store {
        background-image: url("../../../../assets/images/store.png");
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;

        @media screen and (max-width: 599px) {
          font-size: 8px;
          text-align: center;
        }

        &__wrapper {
          max-width: 1024px;
          margin: 0 auto;
          padding: 4em 1em;
        }

        &__content {
          h3 {
            font-size: 3em;
            letter-spacing: 2px;
            margin-bottom: 0.5em;
            font-weight: bold;
            color: rgba(black, 0.75);
          }

          p {
            font-size: 1.4em;
            margin-bottom: 2.5em;
            color: rgba(black, 0.75);
            font-weight: 650;
          }

          img {
            cursor: pointer;
            @media screen and (max-width: 599px) {
              margin: 0 auto;
              margin-bottom: 3em;
            }
          }
        }

        &__image {
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
