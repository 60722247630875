@mixin website-education-form-theme($theme) {
  app-education-form {
    font-size: 1.2em;

    form {
      padding: 2em 0;
    }

    button {
      @media screen and (max-width: 599px) {
        margin-bottom: 1em !important;
      }
    }
  }
}
