@mixin website-footer-theme($theme) {
  app-website-footer {
    .website-footer {
      background-color: rgba($light-blue-text, 0.03);
      padding: 2em 0;
      &__wrapper {
        max-width: 1024px;
        margin: 0 auto;
      }

      &__subscribe {
        p {
          color: $dark-text;
          font-size: 0.8rem;
        }
        p:nth-of-type(2) {
          color: rgba($dark-text, 0.8);
          font-size: 0.85rem;
          font-weight: 600;
        }
        button {
          color: $light-text;
          background: linear-gradient(to top, $dark-text, $light-blue-text);
          cursor: pointer;
          border: 0;
          padding: 0.8em 1.6em;
          outline: 0;

          &:hover {
            background: linear-gradient(
              to bottom,
              $dark-text,
              $light-blue-text
            );
          }
        }

        input {
          font-size: 1.2em;
          display: block;
          border: 1px solid rgba(gray, 0.5);
          padding: 0.5em 1em;
          outline: 0;
          width: 60%;
        }

        @media screen and (max-width: 959px) {
          padding: 0 0.5em;
          text-align: center;
          input {
            width: 50%;
          }
        }
      }

      h6 {
        font-size: 1rem;
        margin: 1em 0;
        color: rgba($dark-text, 0.8);
      }

      ul {
        list-style-type: none;
        padding: 0;
        text-align: center;

        li {
          margin-bottom: 1.5em;
          font-size: 0.75rem;
          color: rgba($dark-text, 0.8);

          span {
            margin-right: 0.5em;
          }

          a {
            text-decoration: none;

            &:hover {
              text-decoration: underline;
              font-weight: 600;
            }
          }
        }
      }

      &__get-in-touch {
        ul {
          li {
            color: black;
            font-weight: 700;

            .material-icons {
              font-weight: initial;
              color: rgba($dark-text, 0.8);
            }
          }
        }
      }
    }

    .social-icons {
      padding: 1em;
      font-size: 1.7em;
      color: rgba(gray, 0.5);
      text-align: center;

      fa-icon {
        margin-right: 1em;
        cursor: pointer;

        &:nth-child(1):hover {
          color: rgba($dark-text, 0.85);
          transition: all 0.5s ease;
        }

        &:nth-child(2):hover {
          color: rgba(red, 0.85);
          transition: all 0.5s ease;
        }

        &:nth-child(3):hover {
          color: rgba($light-blue-text, 0.85);
          transition: all 0.5s ease;
        }
      }
    }
  }
}
