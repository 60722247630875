@mixin website-basic-info-form-theme($theme) {
  app-basic-info-form {
    font-size: 1.2em;

    form {
      padding: 2em 0;

      mat-radio-button {
        margin-top: 0.5em;
        margin-bottom: 1em;
      }
    }

    button {
      @media screen and (max-width: 599px) {
        margin-bottom: 1em !important;
      }
    }
  }
}
