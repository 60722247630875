@mixin website-header-theme($theme) {
  app-website-header {
    .website-header {
      // background-color: rgba($light-blue-text, 0.03);

      &__wrapper {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0.75rem;
        font-size: 0.8rem;
      }

      &__nav {
        list-style-type: none;
        color: $dark-text;

        li:not(:nth-child(6)):not(.userMenu) {
          cursor: pointer;
          outline: 0;
          a {
            text-decoration: none;
            font-weight: 450;
            letter-spacing: 1px;
            font-weight: bolder;
          }

          &:not(:nth-child(6)) {
            &:hover {
              padding-bottom: 6px;
              border-bottom: 2px solid $dark-text;
              transition: all 0.1s ease-in;
            }
          }
        }

        li.lang {
          &:hover {
            padding-bottom: 6px;
            border-bottom: 2px solid $dark-text;
            transition: all 0.1s ease-in;
          }
          a {
            font-weight: initial !important;
            cursor: pointer;
          }
        }

        .userMenu {
          font-weight: bolder;
          cursor: pointer;

          fa-icon {
            font-size: 1em;
            padding: 0 0.5em;
          }
        }
      }

      $background: map-get($theme, background);

      &__sign-up {
        padding: 0.5em 1.5em;
        border-radius: 22px;
        color: $light-text !important;
        border: 1px solid $light-background;
        font-weight: bolder;
        outline: 0;
        background: linear-gradient(to top, $dark-text, $light-blue-text);
        cursor: pointer;

        &:hover {
          background: linear-gradient(to bottom, $dark-text, $light-blue-text);
        }
      }

      &__sign-in {
      }
    }
  }
}
